import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import mitt from 'mitt'
import '@fortawesome/fontawesome-free/css/all.css'

const emitter = mitt()
const app = createApp(App)

app.config.globalProperties.emitter = emitter

app.use(router).mount('#app')
