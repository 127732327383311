const EVENTS_KEY = 'life-review-events'
const GOALS_KEY = 'life-review-goals'
const REFLECTIONS_KEY = 'life-review-reflections'

export const saveEvents = (events) => {
  localStorage.setItem(EVENTS_KEY, JSON.stringify(events))
}

export const loadEvents = () => {
  const events = localStorage.getItem(EVENTS_KEY)
  return events ? JSON.parse(events) : []
}

export const saveGoals = (goals) => {
  localStorage.setItem('goals', JSON.stringify(goals))
}

export const loadGoals = () => {
  const goals = JSON.parse(localStorage.getItem('goals') || '[]')
  return goals.map(goal => ({
    ...goal,
    subTasks: goal.subTasks || []
  }))
}

export const saveReflections = (reflections) => {
  localStorage.setItem(REFLECTIONS_KEY, JSON.stringify(reflections))
}

export const loadReflections = () => {
  const reflections = localStorage.getItem(REFLECTIONS_KEY)
  return reflections ? JSON.parse(reflections) : []
}

export const loadPersonalInfo = () => {
  const personalInfo = localStorage.getItem('personalInfo')
  return personalInfo ? JSON.parse(personalInfo) : {}
}
