<template>
  <div class="bg-white rounded-lg shadow-md p-6">
    <h2 class="text-2xl font-bold mb-4 text-gray-800">日历视图</h2>
    <div class="flex justify-between items-center mb-4">
      <button @click="previousMonth" class="btn btn-secondary">&lt; 上个月</button>
      <h3 class="text-xl font-semibold">{{ currentMonthYear }}</h3>
      <button @click="nextMonth" class="btn btn-secondary">下个月 &gt;</button>
    </div>
    <div class="grid grid-cols-7 gap-2">
      <div v-for="day in 7" :key="day" class="text-center font-bold">
        {{ getDayName(day) }}
      </div>
      <div v-for="date in dates" :key="date.toISOString()" 
           class="border p-2 h-32 overflow-y-auto"
           :class="{ 'bg-blue-50': isToday(date) }">
        <div class="text-sm text-gray-500">{{ formatDate(date) }}</div>
        <div v-for="event in getEventsForDate(date)" :key="event.id" 
             class="text-xs mt-1 p-1 rounded cursor-pointer" 
             :class="getEventClass(event)"
             @click="showEventDetails(event)">
          {{ event.title }}
        </div>
        <div v-for="goal in getGoalsForDate(date)" :key="goal.id" 
             class="text-xs mt-1 p-1 rounded bg-yellow-200 text-yellow-800 cursor-pointer"
             @click="showGoalDetails(goal)">
          目标: {{ goal.title }}
        </div>
      </div>
    </div>

    <!-- 事件详情模态框 -->
    <div v-if="selectedEvent" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-white p-6 rounded-lg max-w-md w-full">
        <h3 class="text-xl font-bold mb-2">{{ selectedEvent.title }}</h3>
        <p><strong>日期：</strong> {{ selectedEvent.date }}</p>
        <p><strong>类别：</strong> {{ selectedEvent.category }}</p>
        <p><strong>描述：</strong> {{ selectedEvent.description }}</p>
        <button @click="selectedEvent = null" class="mt-4 btn btn-primary">关闭</button>
      </div>
    </div>

    <!-- 目标详情模态框 -->
    <div v-if="selectedGoal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-white p-6 rounded-lg max-w-md w-full">
        <h3 class="text-xl font-bold mb-2">{{ selectedGoal.title }}</h3>
        <p><strong>开始日期：</strong> {{ selectedGoal.startDate }}</p>
        <p><strong>结束日期：</strong> {{ selectedGoal.endDate }}</p>
        <p><strong>进度：</strong> {{ selectedGoal.progress }}%</p>
        <p><strong>描述：</strong> {{ selectedGoal.description }}</p>
        <button @click="selectedGoal = null" class="mt-4 btn btn-primary">关闭</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { loadEvents, loadGoals } from '../utils/storage'

export default {
  setup() {
    const currentDate = ref(new Date())
    const events = ref(loadEvents())
    const goals = ref(loadGoals())
    const selectedEvent = ref(null)
    const selectedGoal = ref(null)

    const dates = computed(() => {
      const firstDay = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth(), 1)
      const lastDay = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth() + 1, 0)
      const days = []
      for (let d = new Date(firstDay); d <= lastDay; d.setDate(d.getDate() + 1)) {
        days.push(new Date(d))
      }
      return days
    })

    const currentMonthYear = computed(() => {
      return currentDate.value.toLocaleString('default', { month: 'long', year: 'numeric' })
    })

    const getDayName = (day) => {
      return ['日', '一', '二', '三', '四', '五', '六'][day % 7]
    }

    const formatDate = (date) => {
      return date.getDate()
    }

    const getEventsForDate = (date) => {
      return events.value.filter(event => new Date(event.date).toDateString() === date.toDateString())
    }

    const getGoalsForDate = (date) => {
      return goals.value.filter(goal => {
        const endDate = new Date(goal.endDate)
        return endDate.toDateString() === date.toDateString()
      })
    }

    const getEventClass = (event) => {
      const classes = {
        'work': 'bg-blue-200 text-blue-800',
        'study': 'bg-green-200 text-green-800',
        'family': 'bg-pink-200 text-pink-800',
        'social': 'bg-purple-200 text-purple-800'
      }
      return classes[event.category] || 'bg-gray-200 text-gray-800'
    }

    const isToday = (date) => {
      const today = new Date()
      return date.toDateString() === today.toDateString()
    }

    const previousMonth = () => {
      currentDate.value = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth() - 1, 1)
    }

    const nextMonth = () => {
      currentDate.value = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth() + 1, 1)
    }

    const showEventDetails = (event) => {
      selectedEvent.value = event
    }

    const showGoalDetails = (goal) => {
      selectedGoal.value = goal
    }

    return {
      dates,
      currentMonthYear,
      getDayName,
      formatDate,
      getEventsForDate,
      getGoalsForDate,
      getEventClass,
      isToday,
      previousMonth,
      nextMonth,
      showEventDetails,
      showGoalDetails,
      selectedEvent,
      selectedGoal
    }
  }
}
</script>
