<template>
  <div class="bg-white rounded-lg shadow-md p-6">
    <h2 class="text-2xl font-bold mb-6 text-gray-800">事件记录</h2>
    <form @submit.prevent="addEvent" class="space-y-4">
      <div>
        <label for="title" class="block mb-1 text-sm font-medium text-gray-700">标题</label>
        <input v-model="newEvent.title" id="title" type="text" class="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500" required>
      </div>
      <div>
        <label for="date" class="block mb-1 text-sm font-medium text-gray-700">日期</label>
        <input v-model="newEvent.date" id="date" type="date" class="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500" required>
      </div>
      <div>
        <label for="description" class="block mb-1 text-sm font-medium text-gray-700">描述</label>
        <textarea v-model="newEvent.description" id="description" class="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500" rows="3"></textarea>
      </div>
      <div>
        <label for="category" class="block mb-1 text-sm font-medium text-gray-700">分类</label>
        <select v-model="newEvent.category" id="category" class="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500">
          <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
        </select>
      </div>
      <div>
        <label for="relatedGoal" class="block mb-1 text-sm font-medium text-gray-700">相关目标</label>
        <select v-model="newEvent.relatedGoalId" id="relatedGoal" class="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500">
          <option value="">无相关目标</option>
          <option v-for="goal in goals" :key="goal.id" :value="goal.id">{{ goal.title }}</option>
        </select>
      </div>
      <div>
        <label for="status" class="block mb-1 text-sm font-medium text-gray-700">状态</label>
        <select v-model="newEvent.status" id="status" class="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500">
          <option value="pending">进行中</option>
          <option value="completed">已完成</option>
          <option value="failed">失败</option>
        </select>
      </div>
      <div>
        <label for="tags" class="block mb-1 text-sm font-medium text-gray-700">标签</label>
        <input v-model="newEvent.tags" id="tags" type="text" class="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500">
        <p class="mt-1 text-sm text-gray-500">建议标签: {{ suggestedTags }}</p>
      </div>
      <button type="submit" class="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300">添加事件</button>
    </form>
  </div>
</template>

<script>
import { ref, onMounted, watch, getCurrentInstance, computed } from 'vue'
import { saveEvents, loadEvents, loadGoals, saveGoals, loadPersonalInfo } from '../utils/storage'

export default {
  props: {
    editingEvent: {
      type: Object,
      default: () => null
    }
  },
  emits: ['event-added'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    const newEvent = ref({
      title: '',
      date: '',
      description: '',
      category: '',
      relatedGoalId: '',
      status: 'pending',
      tags: ''
    })
    const goals = ref([])
    const categories = ref([])
    const personalInfo = ref({})

    onMounted(() => {
      goals.value = loadGoals()
      loadCategories()
      personalInfo.value = loadPersonalInfo()
      proxy.emitter.on('categories-updated', loadCategories)
    })

    const loadCategories = () => {
      const savedCategories = localStorage.getItem('eventCategories')
      if (savedCategories) {
        categories.value = JSON.parse(savedCategories)
      } else {
        categories.value = ['工作', '学习', '家庭', '社交'] // 默认分类
      }
      // 如果 newEvent.category 为空，设置为第一个分类
      if (!newEvent.value.category && categories.value.length > 0) {
        newEvent.value.category = categories.value[0]
      }
    }

    watch(() => props.editingEvent, (event) => {
      if (event) {
        newEvent.value = { 
          ...event,
          tags: Array.isArray(event.tags) ? event.tags.join(', ') : ''
        }
      }
    }, { immediate: true })

    const suggestedTags = computed(() => {
      const interests = personalInfo.value.interests?.split(',') || []
      const values = personalInfo.value.values?.split(',') || []
      return [...interests, ...values].join(', ')
    })

    const addEvent = () => {
      const events = loadEvents()
      const eventToSave = {
        ...newEvent.value,
        tags: newEvent.value.tags.split(',').map(tag => tag.trim()).filter(tag => tag !== '')
      }

      if (props.editingEvent) {
        const index = events.findIndex(e => e.id === props.editingEvent.id)
        if (index !== -1) {
          events[index] = eventToSave
        }
      } else {
        eventToSave.id = Date.now()
        events.push(eventToSave)
      }
      saveEvents(events)

      if (eventToSave.relatedGoalId) {
        updateGoalProgress(eventToSave.relatedGoalId, eventToSave.status)
      }

      emit('event-added')
      newEvent.value = { title: '', date: '', description: '', category: '', relatedGoalId: '', status: 'pending', tags: '' }
    }

    const updateGoalProgress = (goalId, eventStatus) => {
      const goals = loadGoals()
      const goalIndex = goals.findIndex(g => g.id === goalId)
      if (goalIndex !== -1) {
        const goal = goals[goalIndex]
        const relatedEvents = loadEvents().filter(e => e.relatedGoalId === goalId)
        const completedEvents = relatedEvents.filter(e => e.status === 'completed').length
        const totalEvents = relatedEvents.length
        goal.progress = Math.round((completedEvents / totalEvents) * 100)
        goal.completed = goal.progress === 100
        goals[goalIndex] = goal
        saveGoals(goals)
      }
    }

    return {
      newEvent,
      goals,
      categories,
      addEvent,
      suggestedTags
    }
  }
}
</script>

