<template>
  <div v-if="showReminder" class="fixed bottom-4 right-4 bg-white rounded-lg shadow-md p-4 max-w-sm">
    <h3 class="text-lg font-semibold mb-2">提醒</h3>
    <p>{{ reminderMessage }}</p>
    <div class="mt-4 flex justify-end">
      <button @click="dismissReminder" class="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded">稍后提醒</button>
      <button @click="handleReminder" class="px-4 py-2 bg-blue-500 text-white rounded">处理</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { loadEvents, loadGoals, loadReflections } from '../utils/storage'

export default {
  setup() {
    const router = useRouter()
    const { proxy } = getCurrentInstance()
    const showReminder = ref(false)
    const reminderMessage = ref('')
    const reminderType = ref('')
    const notificationSettings = ref({
      reminderFrequency: 'weekly',
      eventReminders: true,
      goalReminders: true,
      reflectionReminders: true
    })

    const loadNotificationSettings = () => {
      const savedSettings = localStorage.getItem('notificationSettings')
      if (savedSettings) {
        notificationSettings.value = JSON.parse(savedSettings)
      }
    }

    const checkReminders = () => {
      if (!notificationSettings.value.eventReminders && 
          !notificationSettings.value.goalReminders && 
          !notificationSettings.value.reflectionReminders) {
        return
      }

      const events = loadEvents()
      const goals = loadGoals()
      const reflections = loadReflections()

      // 检查即将到期的事件
      if (notificationSettings.value.eventReminders) {
        const upcomingEvent = events.find(e => {
          const eventDate = new Date(e.date)
          const today = new Date()
          const diffDays = Math.ceil((eventDate - today) / (1000 * 60 * 60 * 24))
          return diffDays > 0 && diffDays <= 3 && e.status === 'pending'
        })

        if (upcomingEvent) {
          showReminder.value = true
          reminderMessage.value = `您有一个即将到期的事件：${upcomingEvent.title}`
          reminderType.value = 'event'
          return
        }
      }

      // 检查长时间未更新的目标
      if (notificationSettings.value.goalReminders) {
        const neglectedGoal = goals.find(g => {
          const lastUpdate = new Date(g.lastUpdateDate || g.startDate)
          const today = new Date()
          const diffDays = Math.ceil((today - lastUpdate) / (1000 * 60 * 60 * 24))
          return diffDays > 7 && !g.completed
        })

        if (neglectedGoal) {
          showReminder.value = true
          reminderMessage.value = `您有一个长时间未更新的目标：${neglectedGoal.title}`
          reminderType.value = 'goal'
          return
        }
      }

      // 检查是否需要进行复盘
      if (notificationSettings.value.reflectionReminders) {
        const lastReflection = reflections[reflections.length - 1]
        const today = new Date()
        const daysSinceLastReflection = lastReflection
          ? Math.ceil((today - new Date(lastReflection.date)) / (1000 * 60 * 60 * 24))
          : Infinity

        let shouldRemind = false
        switch (notificationSettings.value.reminderFrequency) {
          case 'daily':
            shouldRemind = daysSinceLastReflection >= 1
            break
          case 'weekly':
            shouldRemind = daysSinceLastReflection >= 7
            break
          case 'biweekly':
            shouldRemind = daysSinceLastReflection >= 14
            break
          case 'monthly':
            shouldRemind = daysSinceLastReflection >= 30
            break
        }

        if (shouldRemind) {
          showReminder.value = true
          reminderMessage.value = '是否进行新的复盘？'
          reminderType.value = 'reflection'
        }
      }
    }

    const dismissReminder = () => {
      showReminder.value = false
      setTimeout(checkReminders, 1000 * 60 * 60) // 1小时后再次检查
    }

    const handleReminder = () => {
      showReminder.value = false
      switch (reminderType.value) {
        case 'event':
          router.push('/events')
          break
        case 'goal':
          router.push('/goals')
          break
        case 'reflection':
          router.push('/reflections')
          break
      }
    }

    onMounted(() => {
      loadNotificationSettings()
      checkReminders()
      setInterval(checkReminders, 1000 * 60 * 60) // 每小时检查一次
    })

    watch(() => router.currentRoute.value.path, () => {
      showReminder.value = false
    })

    proxy.emitter.on('notification-settings-updated', loadNotificationSettings)

    return {
      showReminder,
      reminderMessage,
      dismissReminder,
      handleReminder
    }
  }
}
</script>
