<template>
  <div class="container mx-auto px-4 py-8">
    <h2 class="text-3xl font-bold mb-8 text-gray-800">目标</h2>
    <div class="mb-4">
      <button @click="toggleForm" class="btn btn-primary">
        {{ showForm ? '查看目标列表' : '添加新目标' }}
      </button>
    </div>
    <GoalSetter v-if="showForm" @goal-added="onGoalAdded" @goal-updated="onGoalUpdated" :editingGoal="editingGoal" />
    <GoalList v-else :goals="goals" @edit-goal="onEditGoal" />
    <GoalGanttChart v-if="goals.length > 0" :goals="goals" class="mt-8" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import GoalSetter from '../components/GoalSetter.vue'
import GoalList from '../components/GoalList.vue'
import GoalGanttChart from '../components/GoalGanttChart.vue'
import { loadGoals, saveGoals } from '../utils/storage'

export default {
  components: {
    GoalSetter,
    GoalList,
    GoalGanttChart
  },
  setup() {
    const showForm = ref(false)
    const editingGoal = ref(null)
    const goals = ref([])

    const loadData = () => {
      goals.value = loadGoals()
      console.log('Loaded goals:', goals.value) // 添加日志
    }

    onMounted(loadData)

    const toggleForm = () => {
      showForm.value = !showForm.value
      if (!showForm.value) {
        editingGoal.value = null
      }
    }

    const onGoalAdded = (newGoal) => {
      goals.value.push(newGoal)
      saveGoals(goals.value)
      showForm.value = false
    }

    const onGoalUpdated = (updatedGoal) => {
      const index = goals.value.findIndex(g => g.id === updatedGoal.id)
      if (index !== -1) {
        goals.value[index] = updatedGoal
        saveGoals(goals.value)
      }
      showForm.value = false
      editingGoal.value = null
    }

    const onEditGoal = (goal) => {
      editingGoal.value = goal
      showForm.value = true
    }

    return {
      showForm,
      editingGoal,
      goals,
      toggleForm,
      onGoalAdded,
      onGoalUpdated,
      onEditGoal
    }
  }
}
</script>
