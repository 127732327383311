import OSS from 'ali-oss';
import COS from 'cos-js-sdk-v5';
import AWS from 'aws-sdk';

let client = null;

const initCloudClient = () => {
  if (client) return client;

  const cloudSettings = JSON.parse(localStorage.getItem('cloudSettings') || '{}');

  switch (cloudSettings.service) {
    case 'alioss':
      client = new OSS({
        region: cloudSettings.region,
        accessKeyId: cloudSettings.accessKeyId,
        accessKeySecret: cloudSettings.accessKeySecret,
        bucket: cloudSettings.bucket,
        secure: true,
        timeout: 60000,
      });
      break;
    case 'tencentcos':
      client = new COS({
        SecretId: cloudSettings.accessKeyId,
        SecretKey: cloudSettings.accessKeySecret,
      });
      break;
    case 'awss3':
      AWS.config.update({
        accessKeyId: cloudSettings.accessKeyId,
        secretAccessKey: cloudSettings.accessKeySecret,
        region: cloudSettings.region
      });
      client = new AWS.S3({
        params: { Bucket: cloudSettings.bucket }
      });
      break;
    default:
      throw new Error('Unsupported cloud service');
  }

  return client;
};

export const uploadData = async (userId, data) => {
  try {
    console.log('Initializing cloud client...');
    const cloudClient = initCloudClient();
    console.log('Cloud client initialized.');

    console.log('Preparing data for upload...');
    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    console.log('Data prepared.');

    const cloudSettings = JSON.parse(localStorage.getItem('cloudSettings') || '{}');

    console.log('Starting upload...');
    let result;
    switch (cloudSettings.service) {
      case 'tencentcos':
        result = await new Promise((resolve, reject) => {
          cloudClient.putObject({
            Bucket: cloudSettings.bucket,
            Region: cloudSettings.region,
            Key: `users/${userId}/data.json`,
            Body: blob,
            ContentType: 'application/json',
          }, (err, data) => {
            if (err) {
              console.error('Tencent COS upload error:', err);
              reject(err);
            } else {
              resolve(data);
            }
          });
        });
        break;
      case 'alioss':
        result = await cloudClient.put(`users/${userId}/data.json`, blob);
        break;
      case 'awss3':
        result = await new Promise((resolve, reject) => {
          cloudClient.upload({
            Key: `users/${userId}/data.json`,
            Body: blob,
            ContentType: 'application/json'
          }, (err, data) => {
            if (err) {
              console.error('AWS S3 upload error:', err);
              reject(err);
            } else {
              resolve(data);
            }
          });
        });
        break;
    }
    console.log('Upload successful:', result);
    return result.url || result.Location;
  } catch (error) {
    console.error('Upload failed', error);
    if (error.code === 'InvalidAccessKeyId') {
      throw new Error('上传失败：访问密钥 ID 无效，请检查您的云存储设置');
    } else if (error.code === 'SignatureDoesNotMatch') {
      throw new Error('上传失败：签名不匹配，请检查您的访问密钥密码');
    } else if (error.code === 'NoSuchBucket') {
      throw new Error('上传失败：存储桶不存在，请检查您的存储桶名称');
    } else if (error.code === 'AccessDenied') {
      throw new Error('上传失败：访问被拒绝，请检查云存储配置和权限');
    } else if (error.code === 'NetworkError') {
      throw new Error('上传失败：网络错误，请检查您的网络连接');
    } else if (error.status === 403) {
      throw new Error('上传失败：CORS 配置错误，请检查云存储的 CORS 设置');
    } else {
      throw new Error(`上传失败：${error.message}`);
    }
  }
};

export const downloadData = async (userId) => {
  try {
    console.log('Initializing cloud client for download...');
    const cloudClient = initCloudClient();
    console.log('Cloud client initialized for download.');

    const cloudSettings = JSON.parse(localStorage.getItem('cloudSettings') || '{}');

    console.log('Starting download...');
    let result;
    switch (cloudSettings.service) {
      case 'alioss':
        result = await cloudClient.get(`users/${userId}/data.json`);
        break;
      case 'tencentcos':
        result = await new Promise((resolve, reject) => {
          cloudClient.getObject({
            Bucket: cloudSettings.bucket,
            Region: cloudSettings.region,
            Key: `users/${userId}/data.json`,
          }, (err, data) => {
            if (err) reject(err);
            else resolve(data);
          });
        });
        break;
      case 'awss3':
        result = await new Promise((resolve, reject) => {
          cloudClient.getObject({
            Key: `users/${userId}/data.json`
          }, (err, data) => {
            if (err) reject(err);
            else resolve(data);
          });
        });
        break;
    }
    console.log('Download successful:', result);
    return JSON.parse(result.content || result.Body.toString());
  } catch (error) {
    console.error('Download failed', error);
    if (error.code === 'NoSuchKey') {
      throw new Error('下载失败：找不到数据，可能是首次同步');
    } else {
      throw new Error(`下载失败：${error.message}`);
    }
  }
};