<template>
  <div class="bg-white rounded-lg shadow-md p-6">
    <h2 class="text-2xl font-bold mb-6 text-gray-800">复盘</h2>
    
    <!-- 添加价值观提示 -->
    <div v-if="personalInfo.values" class="mb-4 p-4 bg-green-100 rounded-lg">
      <h3 class="font-bold text-green-800 mb-2">您的价值观：</h3>
      <p class="text-green-700">{{ personalInfo.values }}</p>
      <p class="mt-2 text-green-600 italic">在复盘时，请思考您的行为是否符合这些价值观。</p>
    </div>
    
    <form @submit.prevent="addOrUpdateReflection" class="space-y-4">
      <div>
        <label for="reflectionDate" class="block mb-1 text-sm font-medium text-gray-700">日期</label>
        <input v-model="newReflection.date" id="reflectionDate" type="date" class="input" required>
      </div>
      <div>
        <label for="template" class="block mb-1 text-sm font-medium text-gray-700">模板</label>
        <select v-model="selectedTemplate" @change="applyTemplate" id="template" class="input">
          <option value="">选择模板</option>
          <option value="standard">标准模板</option>
          <option value="swot">SWOT分析</option>
          <option value="5w2h">5W2H</option>
        </select>
      </div>
      <!-- 根据选择的模板动态显示不同的输入字段 -->
      <template v-if="selectedTemplate === 'standard'">
        <div>
          <label for="achievements" class="block mb-1 text-sm font-medium text-gray-700">成就</label>
          <textarea v-model="newReflection.achievements" id="achievements" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="challenges" class="block mb-1 text-sm font-medium text-gray-700">挑战</label>
          <textarea v-model="newReflection.challenges" id="challenges" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="learnings" class="block mb-1 text-sm font-medium text-gray-700">学习</label>
          <textarea v-model="newReflection.learnings" id="learnings" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="nextSteps" class="block mb-1 text-sm font-medium text-gray-700">下一步计划</label>
          <textarea v-model="newReflection.nextSteps" id="nextSteps" class="input" rows="3"></textarea>
        </div>
      </template>
      <template v-else-if="selectedTemplate === 'swot'">
        <div>
          <label for="strengths" class="block mb-1 text-sm font-medium text-gray-700">优势 (Strengths)</label>
          <textarea v-model="newReflection.strengths" id="strengths" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="weaknesses" class="block mb-1 text-sm font-medium text-gray-700">劣势 (Weaknesses)</label>
          <textarea v-model="newReflection.weaknesses" id="weaknesses" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="opportunities" class="block mb-1 text-sm font-medium text-gray-700">机会 (Opportunities)</label>
          <textarea v-model="newReflection.opportunities" id="opportunities" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="threats" class="block mb-1 text-sm font-medium text-gray-700">威胁 (Threats)</label>
          <textarea v-model="newReflection.threats" id="threats" class="input" rows="3"></textarea>
        </div>
      </template>
      <template v-else-if="selectedTemplate === '5w2h'">
        <div>
          <label for="what" class="block mb-1 text-sm font-medium text-gray-700">做什么 (What)</label>
          <textarea v-model="newReflection.what" id="what" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="why" class="block mb-1 text-sm font-medium text-gray-700">为什么 (Why)</label>
          <textarea v-model="newReflection.why" id="why" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="where" class="block mb-1 text-sm font-medium text-gray-700">在哪里 (Where)</label>
          <textarea v-model="newReflection.where" id="where" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="when" class="block mb-1 text-sm font-medium text-gray-700">什么时候 (When)</label>
          <textarea v-model="newReflection.when" id="when" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="who" class="block mb-1 text-sm font-medium text-gray-700">谁 (Who)</label>
          <textarea v-model="newReflection.who" id="who" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="how" class="block mb-1 text-sm font-medium text-gray-700">如何 (How)</label>
          <textarea v-model="newReflection.how" id="how" class="input" rows="3"></textarea>
        </div>
        <div>
          <label for="howMuch" class="block mb-1 text-sm font-medium text-gray-700">多少 (How Much)</label>
          <textarea v-model="newReflection.howMuch" id="howMuch" class="input" rows="3"></textarea>
        </div>
      </template>
      <!-- 其他公共字段 -->
      <div>
        <label for="relatedGoal" class="block mb-1 text-sm font-medium text-gray-700">相关目标</label>
        <select v-model="newReflection.relatedGoalId" id="relatedGoal" class="input">
          <option value="">无相关目标</option>
          <option v-for="goal in goals" :key="goal.id" :value="goal.id">{{ goal.title }}</option>
        </select>
      </div>
      <div>
        <label for="mood" class="block mb-1 text-sm font-medium text-gray-700">情绪</label>
        <select v-model="newReflection.mood" id="mood" class="input">
          <option value="very_happy">非常开心</option>
          <option value="happy">开心</option>
          <option value="neutral">一般</option>
          <option value="sad">难过</option>
          <option value="very_sad">非常难过</option>
        </select>
      </div>
      <div>
        <label for="tags" class="block mb-1 text-sm font-medium text-gray-700">标签（用逗号分隔）</label>
        <input v-model="newReflection.tags" id="tags" type="text" class="input">
      </div>
      <div>
        <label for="score" class="block mb-1 text-sm font-medium text-gray-700">复盘评分 (1-10)</label>
        <input v-model.number="newReflection.score" id="score" type="number" min="1" max="10" class="input" required>
      </div>
      <div class="mb-4">
        <h4 class="font-medium text-gray-700">价值观参考</h4>
        <p class="text-sm text-gray-500">{{ personalValues }}</p>
      </div>
      <div class="mb-4">
        <h4 class="font-medium text-gray-700">长期目标进展</h4>
        <textarea v-model="newReflection.longTermGoalProgress" rows="3" class="w-full p-2 border rounded"></textarea>
      </div>
      <button type="submit" class="btn btn-primary w-full">{{ isEditing ? '更新复盘' : '添加复盘' }}</button>
    </form>
    
    <div class="mt-8">
      <h3 class="text-xl font-bold mb-4 text-gray-800">复盘记录</h3>
      <ul class="space-y-4">
        <li v-for="reflection in reflections" :key="reflection.id" class="border border-gray-200 p-4 rounded-md hover:bg-notion-gray transition duration-300">
          <h4 class="text-lg font-semibold text-gray-800">{{ reflection.date }}</h4>
          <p v-if="reflection.relatedGoalId" class="text-sm text-gray-600">相关目标: {{ getGoalTitle(reflection.relatedGoalId) }}</p>
          <p class="mt-2"><strong>成就：</strong>{{ reflection.achievements }}</p>
          <p class="mt-2"><strong>挑战：</strong>{{ reflection.challenges }}</p>
          <p class="mt-2"><strong>学习：</strong>{{ reflection.learnings }}</p>
          <p class="mt-2"><strong>下一步计划：</strong>{{ reflection.nextSteps }}</p>
          <p class="mt-2"><strong>情绪：</strong>{{ getMoodEmoji(reflection.mood) }}</p>
          <p class="mt-2"><strong>标签：</strong>{{ (reflection.tags || []).join(', ') }}</p>
          <p class="mt-2"><strong>评分：</strong>{{ reflection.score }}/10</p>
          <div class="mt-2 space-x-2">
            <button @click="editReflection(reflection)" class="btn btn-primary">编辑</button>
            <button @click="deleteReflection(reflection.id)" class="btn btn-danger">删除</button>
            <button @click="exportReflection(reflection)" class="btn btn-secondary">导出</button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue'
import { saveReflections, loadReflections, loadGoals, loadPersonalInfo } from '../utils/storage'

export default {
  props: {
    editingReflection: {
      type: Object,
      default: null
    }
  },
  emits: ['reflection-added', 'reflection-updated'],
  setup(props, { emit }) {
    const newReflection = ref({
      date: '',
      achievements: '',
      challenges: '',
      learnings: '',
      nextSteps: '',
      relatedGoalId: '',
      mood: 'neutral',
      tags: [],
      score: 5,
      // SWOT 分析字段
      strengths: '',
      weaknesses: '',
      opportunities: '',
      threats: '',
      // 5W2H 字段
      what: '',
      why: '',
      where: '',
      when: '',
      who: '',
      how: '',
      howMuch: '',
      longTermGoalProgress: ''
    })
    const reflections = ref(loadReflections())
    const goals = ref([])
    const selectedTemplate = ref('')
    const isEditing = ref(false)
    const personalInfo = ref(loadPersonalInfo())

    function resetForm() {
      newReflection.value = {
        date: '',
        achievements: '',
        challenges: '',
        learnings: '',
        nextSteps: '',
        relatedGoalId: '',
        mood: 'neutral',
        tags: [],
        score: 5,
        strengths: '',
        weaknesses: '',
        opportunities: '',
        threats: '',
        what: '',
        why: '',
        where: '',
        when: '',
        who: '',
        how: '',
        howMuch: '',
        longTermGoalProgress: ''
      }
      selectedTemplate.value = ''
      isEditing.value = false
    }

    onMounted(() => {
      goals.value = loadGoals()
    })

    watch(() => props.editingReflection, (reflection) => {
      if (reflection) {
        newReflection.value = { ...reflection }
        selectedTemplate.value = reflection.template || ''
        isEditing.value = true
      } else {
        resetForm()
      }
    }, { immediate: true })

    const applyTemplate = () => {
      // 清空所有模板相关字段
      newReflection.value = {
        ...newReflection.value,
        achievements: '',
        challenges: '',
        learnings: '',
        nextSteps: '',
        strengths: '',
        weaknesses: '',
        opportunities: '',
        threats: '',
        what: '',
        why: '',
        where: '',
        when: '',
        who: '',
        how: '',
        howMuch: ''
      }
    }

    const addOrUpdateReflection = () => {
      const reflection = {
        ...newReflection.value,
        template: selectedTemplate.value,
        tags: Array.isArray(newReflection.value.tags) 
          ? newReflection.value.tags 
          : (newReflection.value.tags || '').split(',').map(tag => tag.trim()).filter(tag => tag !== '')
      }

      if (isEditing.value) {
        // 更新现有的复盘记录
        const index = reflections.value.findIndex(r => r.id === reflection.id)
        if (index !== -1) {
          reflections.value[index] = reflection
          emit('reflection-updated', reflection)
        }
      } else {
        // 添加新的复盘记录
        reflection.id = Date.now()
        reflections.value.push(reflection)
        emit('reflection-added', reflection)
      }

      saveReflections(reflections.value)
      resetForm()
    }

    const getGoalTitle = (goalId) => {
      const goal = goals.value.find(g => g.id === goalId)
      return goal ? goal.title : '未知目标'
    }

    const getMoodEmoji = (mood) => {
      const moods = {
        very_happy: '😄',
        happy: '🙂',
        neutral: '😐',
        sad: '🙁',
        very_sad: '😢'
      }
      return moods[mood] || '😐'
    }

    const editReflection = (reflection) => {
      newReflection.value = { ...reflection }
      selectedTemplate.value = reflection.template || ''
      isEditing.value = true
    }

    const deleteReflection = (id) => {
      if (confirm('确定要删除这条复盘记录吗？')) {
        reflections.value = reflections.value.filter(r => r.id !== id)
        saveReflections(reflections.value)
        resetForm()
      }
    }

    const exportReflection = (reflection) => {
      // 实现导出功能
    }

    const personalValues = computed(() => personalInfo.value.values || '未设置价值观')

    const suggestReflectionTopics = () => {
      // 基于个人信息提供复盘主题建议
      const lifeStage = personalInfo.value.lifeStage
      const careerGoals = personalInfo.value.careerGoals
      // 实现复盘主题建议逻辑...
    }

    return {
      newReflection,
      reflections,
      goals,
      selectedTemplate,
      isEditing,
      personalInfo,
      addOrUpdateReflection,
      applyTemplate,
      getGoalTitle,
      getMoodEmoji,
      editReflection,
      deleteReflection,
      exportReflection,
      personalValues,
      suggestReflectionTopics
    }
  }
}
</script>