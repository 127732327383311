<template>
  <div class="container mx-auto px-4 py-8">
    <h2 class="text-3xl font-bold mb-8 text-gray-800">统计</h2>
    
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
      <!-- 时间分配饼图 -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <h3 class="text-xl font-semibold mb-4">时间分配</h3>
        <canvas ref="timeAllocationChart"></canvas>
      </div>

      <!-- 目标完成率趋势图 -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <h3 class="text-xl font-semibold mb-4">目标完成率趋势</h3>
        <canvas ref="goalCompletionTrendChart"></canvas>
      </div>

      <!-- 复盘频率柱状图 -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <h3 class="text-xl font-semibold mb-4">复盘频率</h3>
        <canvas ref="reflectionFrequencyChart"></canvas>
      </div>

      <!-- 情绪变化折线图 -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <h3 class="text-xl font-semibold mb-4">情绪变化</h3>
        <canvas ref="moodTrendChart"></canvas>
      </div>

      <!-- 标签使用频率词云 -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <h3 class="text-xl font-semibold mb-4">标签使用频率</h3>
        <div ref="tagCloud" class="h-64"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Chart from 'chart.js/auto'
import * as d3 from 'd3'
import cloud from 'd3-cloud'
import { loadEvents, loadGoals, loadReflections } from '../utils/storage'

export default {
  setup() {
    const timeAllocationChart = ref(null)
    const goalCompletionTrendChart = ref(null)
    const reflectionFrequencyChart = ref(null)
    const moodTrendChart = ref(null)
    const tagCloud = ref(null)

    onMounted(() => {
      const events = loadEvents()
      const goals = loadGoals()
      const reflections = loadReflections()

      createTimeAllocationChart(events)
      createGoalCompletionTrendChart(goals)
      createReflectionFrequencyChart(reflections)
      createMoodTrendChart(reflections)
      createTagCloud(events, goals, reflections)
    })

    const createTimeAllocationChart = (events) => {
      const categories = {}
      events.forEach(event => {
        categories[event.category] = (categories[event.category] || 0) + 1
      })

      const data = {
        labels: Object.keys(categories),
        datasets: [{
          data: Object.values(categories),
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
          ]
        }]
      }

      new Chart(timeAllocationChart.value, {
        type: 'pie',
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: '时间分配'
            }
          }
        }
      })
    }

    const createGoalCompletionTrendChart = (goals) => {
      const monthlyData = {}
      goals.forEach(goal => {
        const date = new Date(goal.endDate)
        const key = `${date.getFullYear()}-${date.getMonth() + 1}`
        if (!monthlyData[key]) {
          monthlyData[key] = { completed: 0, total: 0 }
        }
        monthlyData[key].total++
        if (goal.completed) {
          monthlyData[key].completed++
        }
      })

      const labels = Object.keys(monthlyData).sort()
      const data = labels.map(key => (monthlyData[key].completed / monthlyData[key].total) * 100)

      new Chart(goalCompletionTrendChart.value, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            label: '目标完成率',
            data: data,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              max: 100,
              title: {
                display: true,
                text: '完成率 (%)'
              }
            },
            x: {
              title: {
                display: true,
                text: '月份'
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: '目标完成率趋势'
            }
          }
        }
      })
    }

    const createReflectionFrequencyChart = (reflections) => {
      const monthlyData = {}
      reflections.forEach(reflection => {
        const date = new Date(reflection.date)
        const key = `${date.getFullYear()}-${date.getMonth() + 1}`
        monthlyData[key] = (monthlyData[key] || 0) + 1
      })

      const labels = Object.keys(monthlyData).sort()
      const data = labels.map(key => monthlyData[key])

      new Chart(reflectionFrequencyChart.value, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            label: '复盘次数',
            data: data,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: '次数'
              }
            },
            x: {
              title: {
                display: true,
                text: '月份'
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: '复盘频率'
            }
          }
        }
      })
    }

    const createMoodTrendChart = (reflections) => {
      const moodScores = {
        'very_happy': 5,
        'happy': 4,
        'neutral': 3,
        'sad': 2,
        'very_sad': 1
      }

      const data = reflections.map(reflection => ({
        date: reflection.date,
        score: moodScores[reflection.mood]
      })).sort((a, b) => new Date(a.date) - new Date(b.date))

      new Chart(moodTrendChart.value, {
        type: 'line',
        data: {
          labels: data.map(item => item.date),
          datasets: [{
            label: '情绪评分',
            data: data.map(item => item.score),
            borderColor: 'rgb(255, 99, 132)',
            tension: 0.1
          }]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              max: 5,
              title: {
                display: true,
                text: '情绪评分'
              }
            },
            x: {
              title: {
                display: true,
                text: '日期'
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: '情绪变化趋势'
            }
          }
        }
      })
    }

    const createTagCloud = (events, goals, reflections) => {
      const allTags = [
        ...events.flatMap(event => event.tags || []),
        ...goals.flatMap(goal => goal.tags || []),
        ...reflections.flatMap(reflection => reflection.tags || [])
      ]

      const tagCounts = allTags.reduce((acc, tag) => {
        acc[tag] = (acc[tag] || 0) + 1
        return acc
      }, {})

      const words = Object.keys(tagCounts).map(tag => ({
        text: tag,
        size: 10 + tagCounts[tag] * 5
      }))

      const layout = cloud()
        .size([400, 300])
        .words(words)
        .padding(5)
        .rotate(() => 0)
        .font("Impact")
        .fontSize(d => d.size)
        .on("end", draw)

      layout.start()

      function draw(words) {
        d3.select(tagCloud.value).selectAll("*").remove()
        d3.select(tagCloud.value)
          .append("svg")
          .attr("width", 400)
          .attr("height", 300)
          .append("g")
          .attr("transform", "translate(200,150)")
          .selectAll("text")
          .data(words)
          .enter().append("text")
          .style("font-size", d => d.size + "px")
          .style("font-family", "Impact")
          .attr("text-anchor", "middle")
          .attr("transform", d => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")")
          .text(d => d.text)
          .style("fill", () => `hsl(${Math.random() * 360}, 70%, 50%)`)
      }
    }

    return {
      timeAllocationChart,
      goalCompletionTrendChart,
      reflectionFrequencyChart,
      moodTrendChart,
      tagCloud
    }
  }
}
</script>