<template>
  <div class="bg-white rounded-lg shadow-md p-6">
    <h2 class="text-2xl font-bold mb-6 text-gray-800">目标列表</h2>
    <div v-if="goals.length === 0" class="text-gray-600">
      暂无目标，请添加新目标。
    </div>
    <ul v-else class="space-y-4">
      <li v-for="goal in goals" :key="goal.id" class="border border-gray-200 p-4 rounded-md hover:bg-notion-gray transition duration-300 group">
        <div class="flex justify-between items-start">
          <div class="flex-grow pr-4">
            <h4 class="text-lg font-semibold text-gray-800">{{ goal.title }}</h4>
            <p class="text-sm text-gray-600">{{ goal.startDate }} - {{ goal.endDate }}</p>
            <p class="mt-2 text-gray-700">{{ goal.description }}</p>
            <div class="mt-2 relative w-full h-2 bg-gray-200 rounded-full">
              <div class="absolute top-0 left-0 h-full bg-blue-600 rounded-full" :style="{ width: `${goal.progress}%` }"></div>
            </div>
            <p class="text-sm text-gray-600 mt-1">进度: {{ goal.progress }}%</p>
            
            <!-- 子任务列表 -->
            <div v-if="goal.subTasks && goal.subTasks.length > 0" class="mt-4">
              <h5 class="text-md font-semibold text-gray-700 mb-2">子任务</h5>
              <ul class="space-y-2">
                <li v-for="subTask in goal.subTasks" :key="subTask.id" class="flex items-center">
                  <input type="checkbox" :checked="subTask.completed" @change="toggleSubTask(goal.id, subTask.id)" class="mr-2">
                  <span :class="{ 'line-through': subTask.completed }">{{ subTask.title }}</span>
                </li>
              </ul>
            </div>
            <button @click="addSubTask(goal.id)" class="mt-2 text-blue-600 hover:text-blue-800">
              <i class="fas fa-plus"></i> 添加子任务
            </button>
          </div>
          <div class="hidden group-hover:flex flex-col space-y-2">
            <button @click="editGoal(goal)" class="text-blue-600 hover:text-blue-800">
              <i class="fas fa-edit"></i> 编辑
            </button>
            <button @click="deleteGoal(goal.id)" class="text-red-600 hover:text-red-800">
              <i class="fas fa-trash"></i> 删除
            </button>
            <button @click="showRelatedEvents(goal.id)" class="text-green-600 hover:text-green-800">
              <i class="fas fa-list"></i> 查看事件
            </button>
            <button @click="updateGoalProgress(goal.id)" class="text-yellow-600 hover:text-yellow-800">
              <i class="fas fa-pencil-alt"></i> 修改进度
            </button>
          </div>
        </div>
      </li>
    </ul>

    <!-- 添加模态框 -->
    <div v-if="showModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div class="mt-3 text-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900">相关事件</h3>
          <div class="mt-2 px-7 py-3">
            <ul class="space-y-2">
              <li v-for="event in relatedEvents" :key="event.id" class="text-sm text-gray-500">
                {{ event.title }} - {{ event.date }}
              </li>
            </ul>
          </div>
          <div class="items-center px-4 py-3">
            <button @click="showModal = false" class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300">
              关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { loadGoals, saveGoals, loadEvents } from '../utils/storage'

export default {
  emits: ['edit-goal'],
  setup(props, { emit }) {
    const goals = ref([])
    const showModal = ref(false)
    const relatedEvents = ref([])

    const loadData = () => {
      goals.value = loadGoals()
    }

    onMounted(loadData)

    const editGoal = (goal) => {
      emit('edit-goal', goal)
    }

    const deleteGoal = (id) => {
      if (confirm('确定要删除这个目标吗？')) {
        goals.value = goals.value.filter(g => g.id !== id)
        saveGoals(goals.value)
        loadData()
      }
    }

    const showRelatedEvents = (goalId) => {
      relatedEvents.value = loadEvents().filter(e => e.relatedGoalId === goalId)
      showModal.value = true
    }

    const updateGoalProgress = (goalId) => {
      const goal = goals.value.find(g => g.id === goalId)
      if (goal) {
        const newProgress = prompt('请输入新的进度 (0-100):', goal.progress)
        if (newProgress !== null) {
          const progress = Math.min(100, Math.max(0, parseInt(newProgress, 10)))
          goal.progress = progress
          goal.completed = progress === 100
          saveGoals(goals.value)
          loadData()
        }
      }
    }

    const toggleSubTask = (goalId, subTaskId) => {
      const goalIndex = goals.value.findIndex(g => g.id === goalId)
      if (goalIndex !== -1) {
        const subTaskIndex = goals.value[goalIndex].subTasks.findIndex(st => st.id === subTaskId)
        if (subTaskIndex !== -1) {
          goals.value[goalIndex].subTasks[subTaskIndex].completed = !goals.value[goalIndex].subTasks[subTaskIndex].completed
          updateGoalProgress(goalId)
          saveGoals(goals.value)
        }
      }
    }

    const addSubTask = (goalId) => {
      const goalIndex = goals.value.findIndex(g => g.id === goalId)
      if (goalIndex !== -1) {
        const newSubTask = {
          id: Date.now().toString(),
          title: prompt('请输入子任务标题:'),
          completed: false
        }
        if (newSubTask.title) {
          if (!goals.value[goalIndex].subTasks) {
            goals.value[goalIndex].subTasks = []
          }
          goals.value[goalIndex].subTasks.push(newSubTask)
          saveGoals(goals.value)
        }
      }
    }

    return {
      goals,
      showModal,
      relatedEvents,
      editGoal,
      deleteGoal,
      showRelatedEvents,
      updateGoalProgress,
      toggleSubTask,
      addSubTask
    }
  }
}
</script>
