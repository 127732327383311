<template>
  <div class="bg-white rounded-lg shadow-md p-6">
    <h2 class="text-2xl font-bold mb-6 text-gray-800">复盘记录列表</h2>
    <ul class="space-y-4">
      <li v-for="reflection in reflections" :key="reflection.id" class="border border-gray-200 p-4 rounded-md hover:bg-notion-gray transition duration-300">
        <div class="flex justify-between items-start">
          <div>
            <h4 class="text-lg font-semibold text-gray-800">{{ reflection.date }}</h4>
            <p v-if="reflection.relatedGoalId" class="text-sm text-gray-600">相关目标: {{ getGoalTitle(reflection.relatedGoalId) }}</p>
            <p class="mt-2"><strong>模板：</strong>{{ getTemplateName(reflection.template) }}</p>
            <p class="mt-2"><strong>情绪：</strong>{{ getMoodEmoji(reflection.mood) }}</p>
            <p class="mt-2"><strong>评分：</strong>{{ reflection.score }}/10</p>
            <p class="mt-2"><strong>标签：</strong>{{ reflection.tags?.join(', ') || '' }}</p>
          </div>
          <div class="flex space-x-2">
            <button @click="editReflection(reflection)" class="text-blue-600 hover:text-blue-800">
              <i class="fas fa-edit"></i> 编辑
            </button>
            <button @click="deleteReflection(reflection.id)" class="text-red-600 hover:text-red-800">
              <i class="fas fa-trash"></i> 删除
            </button>
            <button @click="viewDetails(reflection)" class="text-green-600 hover:text-green-800">
              <i class="fas fa-eye"></i> 查看详情
            </button>
          </div>
        </div>
      </li>
    </ul>

    <!-- 详情弹窗 -->
    <div v-if="showModal && selectedReflection" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div class="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
        <div class="mt-3">
          <h3 class="text-lg font-medium text-gray-900 mb-4">复盘详情</h3>
          <div class="mt-2 px-7 py-3">
            <p><strong>日期：</strong> {{ selectedReflection.date }}</p>
            <p><strong>模板：</strong> {{ getTemplateName(selectedReflection.template) }}</p>
            <p><strong>相关目标：</strong> {{ getGoalTitle(selectedReflection.relatedGoalId) }}</p>
            <p><strong>情绪：</strong> {{ getMoodEmoji(selectedReflection.mood) }}</p>
            <p><strong>评分：</strong> {{ selectedReflection.score }}/10</p>
            <p><strong>标签：</strong> {{ selectedReflection.tags?.join(', ') || '' }}</p>
            
            <template v-if="selectedReflection.template === 'standard'">
              <p><strong>成就：</strong> {{ selectedReflection.achievements }}</p>
              <p><strong>挑战：</strong> {{ selectedReflection.challenges }}</p>
              <p><strong>学习：</strong> {{ selectedReflection.learnings }}</p>
              <p><strong>下一步计划：</strong> {{ selectedReflection.nextSteps }}</p>
              <p><strong>长期目标进展：</strong> {{ selectedReflection.longTermGoalProgress }}</p>
            </template>
            
            <template v-else-if="selectedReflection.template === 'swot'">
              <p><strong>优势：</strong> {{ selectedReflection.strengths }}</p>
              <p><strong>劣势：</strong> {{ selectedReflection.weaknesses }}</p>
              <p><strong>机会：</strong> {{ selectedReflection.opportunities }}</p>
              <p><strong>威胁：</strong> {{ selectedReflection.threats }}</p>
              <p><strong>长期目标进展：</strong> {{ selectedReflection.longTermGoalProgress }}</p>
            </template>
            
            <template v-else-if="selectedReflection.template === '5w2h'">
              <p><strong>What：</strong> {{ selectedReflection.what }}</p>
              <p><strong>Why：</strong> {{ selectedReflection.why }}</p>
              <p><strong>Where：</strong> {{ selectedReflection.where }}</p>
              <p><strong>When：</strong> {{ selectedReflection.when }}</p>
              <p><strong>Who：</strong> {{ selectedReflection.who }}</p>
              <p><strong>How：</strong> {{ selectedReflection.how }}</p>
              <p><strong>How Much：</strong> {{ selectedReflection.howMuch }}</p>
              <p><strong>长期目标进展：</strong> {{ selectedReflection.longTermGoalProgress }}</p>
            </template>
          </div>
          <div class="items-center px-4 py-3">
            <button @click="showModal = false" class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300">
              关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { loadReflections, saveReflections, loadGoals } from '../utils/storage'

export default {
  emits: ['edit-reflection'],
  setup(props, { emit }) {
    const reflections = ref([])
    const goals = ref([])
    const showModal = ref(false)
    const selectedReflection = ref(null)

    const loadData = () => {
      reflections.value = loadReflections()
      goals.value = loadGoals()
      console.log('Loaded reflections:', reflections.value)
    }

    onMounted(loadData)

    const getGoalTitle = (goalId) => {
      const goal = goals.value.find(g => g.id === goalId)
      return goal ? goal.title : '未知目标'
    }

    const getTemplateName = (template) => {
      const templates = {
        'standard': '标准模板',
        'swot': 'SWOT分析',
        '5w2h': '5W2H'
      }
      return templates[template] || template || '未知模板'
    }

    const getMoodEmoji = (mood) => {
      const moods = {
        very_happy: '😄',
        happy: '🙂',
        neutral: '😐',
        sad: '🙁',
        very_sad: '😢'
      }
      return moods[mood] || '😐'
    }

    const editReflection = (reflection) => {
      emit('edit-reflection', reflection)
    }

    const deleteReflection = (id) => {
      if (confirm('确定要删除这条复盘记录吗？')) {
        reflections.value = reflections.value.filter(r => r.id !== id)
        saveReflections(reflections.value)
        loadData()
      }
    }

    const viewDetails = (reflection) => {
      selectedReflection.value = reflection
      showModal.value = true
    }

    return {
      reflections,
      showModal,
      selectedReflection,
      getGoalTitle,
      getTemplateName,
      getMoodEmoji,
      editReflection,
      deleteReflection,
      viewDetails
    }
  }
}
</script>