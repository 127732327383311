<template>
  <div class="bg-notion-default min-h-screen font-sans">
    <nav class="bg-white shadow-md">
      <div class="container mx-auto px-4 py-4">
        <div class="flex justify-between items-center">
          <router-link to="/" class="text-2xl font-bold text-gray-800">🌱 人生复盘</router-link>
          <div class="space-x-4">
            <router-link to="/events" class="text-gray-600 hover:text-gray-800">
              <i class="fas fa-calendar-alt mr-1"></i>事件
            </router-link>
            <router-link to="/goals" class="text-gray-600 hover:text-gray-800">
              <i class="fas fa-bullseye mr-1"></i>目标
            </router-link>
            <router-link to="/reflections" class="text-gray-600 hover:text-gray-800">
              <i class="fas fa-book-open mr-1"></i>复盘
            </router-link>
            <router-link to="/statistics" class="text-gray-600 hover:text-gray-800">
              <i class="fas fa-chart-bar mr-1"></i>统计
            </router-link>
            <router-link to="/settings" class="text-gray-600 hover:text-gray-800">
              <i class="fas fa-cog mr-1"></i>设置
            </router-link>
          </div>
        </div>
      </div>
    </nav>
    <router-view></router-view>
    <ReminderSystem />
  </div>
</template>

<script>
import ReminderSystem from './components/ReminderSystem.vue'

export default {
  name: 'App',
  components: {
    ReminderSystem
  }
}
</script>
