<template>
  <div class="bg-white rounded-lg shadow-md p-6">
    <h3 class="text-xl font-semibold mb-4">目标甘特图</h3>
    <div v-if="goals.length > 0" ref="ganttContainer" class="gantt-container"></div>
    <p v-else class="text-gray-600">暂无目标数据可显示</p>
  </div>
</template>

<script>
import { ref, onMounted, watch, onUnmounted } from 'vue'

export default {
  props: {
    goals: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const ganttContainer = ref(null)
    let ganttChart = null

    const createGanttChart = () => {
      if (typeof window.Gantt === 'undefined') {
        console.error('Gantt is not defined. Make sure frappe-gantt is properly loaded.');
        return;
      }
      if (props.goals.length === 0) return

      if (ganttChart) {
        ganttChart.refresh(getGanttTasks())
      } else {
        ganttChart = new window.Gantt(ganttContainer.value, getGanttTasks(), {
          header_height: 50,
          column_width: 30,
          step: 24,
          view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
          bar_height: 20,
          bar_corner_radius: 3,
          arrow_curve: 5,
          padding: 18,
          view_mode: 'Month',
          date_format: 'YYYY-MM-DD',
          language: 'zh',
          on_click: task => {
            alert(`任务 "${task.name}" 被点击了`)
          },
          on_date_change: (task, start, end) => {
            console.log(`任务 "${task.name}" 的日期被修改了`)
            // 这里可以添加更新目标或子任务日期的逻辑
          },
          on_progress_change: (task, progress) => {
            console.log(`任务 "${task.name}" 的进度被修改为 ${progress}%`)
            // 这里可以添加更新目标或子任务进度的逻辑
          }
        })
      }
    }

    const getGanttTasks = () => {
      return props.goals.flatMap(goal => {
        const tasks = [{
          id: goal.id,
          name: goal.title,
          start: goal.startDate || new Date().toISOString().split('T')[0],
          end: goal.endDate || new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
          progress: goal.progress || 0,
          dependencies: ''
        }]

        if (goal.subTasks && goal.subTasks.length > 0) {
          goal.subTasks.forEach(subTask => {
            tasks.push({
              id: subTask.id,
              name: subTask.title,
              start: subTask.startDate || goal.startDate || new Date().toISOString().split('T')[0],
              end: subTask.endDate || goal.endDate || new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
              progress: subTask.completed ? 100 : 0,
              dependencies: goal.id
            })
          })
        }

        return tasks
      })
    }

    onMounted(() => {
      if (props.goals.length > 0) {
        createGanttChart()
      }
    })

    watch(() => props.goals, () => {
      if (props.goals.length > 0) {
        createGanttChart()
      }
    }, { deep: true })

    onUnmounted(() => {
      if (ganttContainer.value) {
        ganttContainer.value.innerHTML = ''
      }
    })

    return {
      ganttContainer
    }
  }
}
</script>

<style scoped>
.gantt-container {
  height: 400px;
  overflow-x: auto;
  overflow-y: hidden;
}

:deep(.gantt) {
  background-color: #f8f9fa;
}

:deep(.gantt .bar-wrapper) {
  fill: #e9ecef;
}

:deep(.gantt .bar) {
  fill: #4dabf7;
}

:deep(.gantt .bar-progress) {
  fill: #228be6;
}

:deep(.gantt .bar-label) {
  fill: #495057;
  font-size: 12px;
}

:deep(.gantt .lower-text, .gantt .upper-text) {
  fill: #868e96;
  font-size: 11px;
}

:deep(.gantt .today-highlight) {
  fill: #ffd43b;
  opacity: 0.3;
}

:deep(.gantt .grid-header) {
  fill: #f1f3f5;
  stroke: #dee2e6;
}

:deep(.gantt .grid-row) {
  fill: #ffffff;
}

:deep(.gantt .row-line) {
  stroke: #e9ecef;
}

:deep(.gantt .tick) {
  stroke: #ced4da;
}

:deep(.gantt .arrow) {
  fill: #adb5bd;
}
</style>
