import { loadEvents, loadGoals, loadReflections, saveEvents, saveGoals, saveReflections } from './storage'

export const exportData = () => {
  const data = {
    events: loadEvents(),
    goals: loadGoals(),
    reflections: loadReflections()
  }
  const jsonString = JSON.stringify(data)
  const blob = new Blob([jsonString], { type: 'application/json' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = 'life-review-data.json'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const importData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      try {
        const data = JSON.parse(event.target.result)
        saveEvents(data.events)
        saveGoals(data.goals)
        saveReflections(data.reflections)
        resolve()
      } catch (error) {
        reject(error)
      }
    }
    reader.onerror = (error) => reject(error)
    reader.readAsText(file)
  })
}
