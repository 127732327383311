<template>
  <div class="bg-white rounded-lg shadow-md p-6">
    <h2 class="text-2xl font-bold mb-6 text-gray-800">事件列表</h2>
    <div class="mb-4 flex justify-between items-center">
      <div>
        <label for="tag-filter" class="block mb-1 text-sm font-medium text-gray-700">按标签筛选</label>
        <input v-model="tagFilter" id="tag-filter" type="text" class="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500" placeholder="输入标签筛选">
      </div>
      <div>
        <label for="sort-order" class="block mb-1 text-sm font-medium text-gray-700">排序</label>
        <select v-model="sortOrder" id="sort-order" class="p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500">
          <option value="desc">降序</option>
          <option value="asc">升序</option>
        </select>
      </div>
    </div>
    <ul class="space-y-4">
      <li v-for="event in sortedEvents" :key="event.id" class="border-l-4 pl-4 py-2 relative group" :class="getBorderColor(event.category)">
        <div class="flex flex-col sm:flex-row justify-between items-start">
          <div class="flex-grow">
            <h3 class="text-xl font-semibold text-gray-800">{{ event.title }}</h3>
            <p class="text-sm text-gray-600">{{ event.date }}</p>
            <p class="mt-2 text-gray-700">{{ event.description }}</p>
            <div class="mt-2">
              <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                {{ getCategoryName(event.category) }}
              </span>
              <span :class="getStatusClass(event.status)" class="inline-block rounded-full px-3 py-1 text-sm font-semibold">
                {{ getStatusName(event.status) }}
              </span>
            </div>
            <div class="mt-2">
              <span v-for="tag in event.tags" :key="tag" class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                {{ tag }}
              </span>
            </div>
          </div>
          <div class="hidden group-hover:flex mt-2 sm:mt-0 sm:ml-4 space-x-2">
            <button @click="editEvent(event)" class="text-blue-600 hover:text-blue-800">
              <i class="fas fa-edit"></i> 编辑
            </button>
            <button @click="deleteEvent(event.id)" class="text-red-600 hover:text-red-800">
              <i class="fas fa-trash"></i> 删除
            </button>
            <button v-if="event.status === 'pending'" @click="updateEventStatus(event.id, 'completed')" class="text-green-600 hover:text-green-800">
              <i class="fas fa-check"></i> 完成
            </button>
            <button v-if="event.status === 'pending'" @click="updateEventStatus(event.id, 'failed')" class="text-yellow-600 hover:text-yellow-800">
              <i class="fas fa-times"></i> 失败
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { loadEvents, saveEvents } from '../utils/storage'

export default {
  emits: ['edit-event'],
  setup(props, { emit }) {
    const events = ref(loadEvents())
    const tagFilter = ref('')
    const sortOrder = ref('desc')

    const filteredEvents = computed(() => {
      if (!tagFilter.value) return events.value
      return events.value.filter(event => 
        event.tags && event.tags.some(tag => 
          tag.toLowerCase().includes(tagFilter.value.toLowerCase())
        )
      )
    })

    const sortedEvents = computed(() => {
      return [...filteredEvents.value].sort((a, b) => {
        const dateA = new Date(a.date)
        const dateB = new Date(b.date)
        return sortOrder.value === 'desc' ? dateB - dateA : dateA - dateB
      })
    })

    const getCategoryName = (category) => {
      const categories = {
        work: '工作',
        study: '学习',
        family: '家庭',
        social: '社交'
      }
      return categories[category] || category
    }

    const getBorderColor = (category) => {
      const colors = {
        work: 'border-notion-green',
        study: 'border-notion-yellow',
        family: 'border-notion-pink',
        social: 'border-notion-purple'
      }
      return colors[category] || 'border-gray-300'
    }

    const getStatusName = (status) => {
      const statuses = {
        pending: '进行中',
        completed: '已完成',
        failed: '失败'
      }
      return statuses[status] || status
    }

    const getStatusClass = (status) => {
      const classes = {
        pending: 'bg-blue-200 text-blue-800',
        completed: 'bg-green-200 text-green-800',
        failed: 'bg-red-200 text-red-800'
      }
      return classes[status] || ''
    }

    const editEvent = (event) => {
      emit('edit-event', event)
    }

    const deleteEvent = (id) => {
      if (confirm('确定要删除这个事件吗？')) {
        events.value = events.value.filter(e => e.id !== id)
        saveEvents(events.value)
      }
    }

    const updateEventStatus = (id, newStatus) => {
      const index = events.value.findIndex(e => e.id === id)
      if (index !== -1) {
        events.value[index].status = newStatus
        saveEvents(events.value)
      }
    }

    return {
      events,
      tagFilter,
      sortOrder,
      sortedEvents,
      getCategoryName,
      getBorderColor,
      getStatusName,
      getStatusClass,
      editEvent,
      deleteEvent,
      updateEventStatus
    }
  }
}
</script>
