<template>
  <div class="container mx-auto px-4 py-8">
    <h2 class="text-3xl font-bold mb-8 text-gray-800">事件</h2>
    <div class="mb-4">
      <button @click="showForm = !showForm" class="btn btn-primary">
        {{ showForm ? '查看事件列表' : '添加新事件' }}
      </button>
    </div>
    <EventRecorder v-if="showForm" @event-added="onEventAdded" :editingEvent="editingEvent" />
    <EventList v-else @edit-event="onEditEvent" />
  </div>
</template>

<script>
import { ref } from 'vue'
import EventRecorder from '../components/EventRecorder.vue'
import EventList from '../components/EventList.vue'

export default {
  components: {
    EventRecorder,
    EventList
  },
  setup() {
    const showForm = ref(false)
    const editingEvent = ref(null)

    const onEventAdded = () => {
      showForm.value = false
      editingEvent.value = null
    }

    const onEditEvent = (event) => {
      editingEvent.value = event
      showForm.value = true
    }

    return {
      showForm,
      editingEvent,
      onEventAdded,
      onEditEvent
    }
  }
}
</script>
