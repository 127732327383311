<template>
  <div class="container mx-auto px-4 py-8">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
      <!-- 座右铭 -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <h2 class="text-2xl font-bold mb-4 text-gray-800">座右铭</h2>
        <p class="text-xl italic text-gray-600">{{ personalInfo.motto || '尚未设置座右铭' }}</p>
      </div>

      <!-- 人生天数 -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <h2 class="text-2xl font-bold mb-4 text-gray-800">{{ personalInfo.nickname || '朋友' }}, 今天是你人生的</h2>
        <p class="text-4xl font-bold text-gray-800">第 <span class="text-blue-600">{{ lifeDays }}</span> 天</p>
      </div>
    </div>
    
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
      <!-- 快速链接 -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <h2 class="text-2xl font-bold mb-4 text-gray-800">快速链接</h2>
        <div class="space-y-4">
          <router-link to="/events?action=add" class="btn btn-primary w-full block text-center">添加新事件</router-link>
          <router-link to="/goals?action=add" class="btn btn-primary w-full block text-center">设置新目标</router-link>
          <router-link to="/reflections?action=add" class="btn btn-primary w-full block text-center">新建复盘</router-link>
        </div>
      </div>

      <!-- 目标进度概览 -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <h2 class="text-2xl font-bold mb-4 text-gray-800">目标进度</h2>
        <div class="flex items-center justify-center">
          <div class="relative w-32 h-32">
            <svg class="w-full h-full" viewBox="0 0 36 36">
              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#eee" stroke-width="3" />
              <path :d="describeArc(18, 18, 15.9155, 0, goalCompletionPercentage * 3.6)" fill="none" stroke="#4CAF50" stroke-width="3" />
            </svg>
            <div class="absolute inset-0 flex items-center justify-center">
              <span class="text-2xl font-bold">{{ goalCompletionPercentage }}%</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 最近复盘摘要 -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <h2 class="text-2xl font-bold mb-4 text-gray-800">最近复盘</h2>
        <div v-if="latestReflection">
          <p class="text-sm text-gray-600">{{ latestReflection.date }}</p>
          <p class="mt-2"><strong>情绪：</strong>{{ getMoodEmoji(latestReflection.mood) }}</p>
          <p class="mt-2"><strong>评分：</strong>{{ latestReflection.score }}/10</p>
          <p class="mt-2"><strong>关键点：</strong>{{ latestReflection.keyPoints }}</p>
        </div>
        <p v-else class="text-gray-600">暂无复盘记录</p>
      </div>
    </div>

    <!-- 日历视图 -->
    <div class="bg-white rounded-lg shadow-md p-6 mb-8">
      <Calendar />
    </div>

    <!-- 导航按钮 -->
    <nav class="flex justify-center space-x-4 mb-8">
      <router-link to="/events" class="btn btn-primary">事件</router-link>
      <router-link to="/goals" class="btn btn-primary">目标</router-link>
      <router-link to="/reflections" class="btn btn-primary">复盘</router-link>
      <router-link to="/statistics" class="btn btn-primary">统计</router-link>
    </nav>

    <!-- 激励语 -->
    <div class="text-center text-xl font-semibold text-gray-700 mt-8">
      {{ motivationalQuote }}
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { loadEvents, loadGoals, loadReflections } from '../utils/storage'
import Calendar from '../components/Calendar.vue'

export default {
  components: {
    Calendar
  },
  setup() {
    const personalInfo = ref({})
    const recentEvents = ref([])
    const goals = ref([])
    const reflections = ref([])
    const motivationalQuote = ref('')
    const latestReflection = ref(null)

    const loadData = () => {
      const events = loadEvents()
      recentEvents.value = events.slice(-3).reverse()
      goals.value = loadGoals()
      reflections.value = loadReflections()
      updateLatestReflection()
      const savedPersonalInfo = localStorage.getItem('personalInfo')
      if (savedPersonalInfo) {
        personalInfo.value = JSON.parse(savedPersonalInfo)
      }
    }

    const updateLatestReflection = () => {
      if (reflections.value.length > 0) {
        const latest = reflections.value[reflections.value.length - 1]
        latestReflection.value = {
          date: latest.date,
          mood: latest.mood,
          score: latest.score,
          keyPoints: getReflectionKeyPoints(latest)
        }
      }
    }

    const getReflectionKeyPoints = (reflection) => {
      // 这里可以根据不同的模板类型提取关键点
      if (reflection.template === 'standard') {
        return reflection.achievements.split('.')[0] + '...'
      } else if (reflection.template === 'swot') {
        return reflection.strengths.split('.')[0] + '...'
      } else if (reflection.template === '5w2h') {
        return reflection.what.split('.')[0] + '...'
      }
      return '无关键点'
    }

    const goalCompletionPercentage = computed(() => {
      if (goals.value.length === 0) return 0
      const totalProgress = goals.value.reduce((sum, goal) => sum + goal.progress, 0)
      return Math.round(totalProgress / goals.value.length)
    })

    const describeArc = (x, y, radius, startAngle, endAngle) => {
      const start = polarToCartesian(x, y, radius, endAngle)
      const end = polarToCartesian(x, y, radius, startAngle)
      const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"
      return [
        "M", start.x, start.y, 
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
      ].join(" ")
    }

    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
      const angleInRadians = (angleInDegrees-90) * Math.PI / 180.0
      return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
      }
    }

    const getMoodEmoji = (mood) => {
      const moods = {
        very_happy: '😄',
        happy: '🙂',
        neutral: '😐',
        sad: '🙁',
        very_sad: '😢'
      }
      return moods[mood] || '😐'
    }

    const lifeDays = computed(() => {
      if (!personalInfo.value.birthday) return '未设置生日'
      const birthday = new Date(personalInfo.value.birthday)
      const today = new Date()
      const diffTime = Math.abs(today - birthday)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    })

    onMounted(() => {
      loadData()
      motivationalQuote.value = "每一个今天都是明天的起点。"
    })

    return {
      personalInfo,
      recentEvents,
      goalCompletionPercentage,
      latestReflection,
      motivationalQuote,
      describeArc,
      getMoodEmoji,
      lifeDays
    }
  }
}
</script>
