<template>
  <div class="container mx-auto px-4 py-8">
    <h2 class="text-3xl font-bold mb-8 text-gray-800">设置</h2>
    <div class="flex">
      <!-- 左侧导航 -->
      <div class="w-1/4 pr-4">
        <ul class="space-y-2">
          <li>
            <button @click="activeSection = 'personal'" :class="{'font-bold': activeSection === 'personal'}" class="w-full text-left py-2 px-4 rounded hover:bg-gray-100">
              <i class="fas fa-user mr-2"></i>个人信息
            </button>
          </li>
          <li>
            <button @click="activeSection = 'categories'" :class="{'font-bold': activeSection === 'categories'}" class="w-full text-left py-2 px-4 rounded hover:bg-gray-100">
              <i class="fas fa-tags mr-2"></i>分类设置
            </button>
          </li>
          <li>
            <button @click="activeSection = 'notifications'" :class="{'font-bold': activeSection === 'notifications'}" class="w-full text-left py-2 px-4 rounded hover:bg-gray-100">
              <i class="fas fa-bell mr-2"></i>通知设置
            </button>
          </li>
          <li>
            <button @click="activeSection = 'dataManagement'" :class="{'font-bold': activeSection === 'dataManagement'}" class="w-full text-left py-2 px-4 rounded hover:bg-gray-100">
              <i class="fas fa-file-export mr-2"></i>导出导入
            </button>
          </li>
          <li>
            <button @click="activeSection = 'cloudSync'" :class="{'font-bold': activeSection === 'cloudSync'}" class="w-full text-left py-2 px-4 rounded hover:bg-gray-100">
              <i class="fas fa-cloud mr-2"></i>云同步
            </button>
          </li>
          <li>
            <button @click="activeSection = 'help'" :class="{'font-bold': activeSection === 'help'}" class="w-full text-left py-2 px-4 rounded hover:bg-gray-100">
              <i class="fas fa-question-circle mr-2"></i>帮助
            </button>
          </li>
        </ul>
      </div>
      
      <!-- 右侧内容 -->
      <div class="w-3/4 bg-white rounded-lg shadow-md p-6">
        <!-- 个人信息设置 -->
        <div v-if="activeSection === 'personal'">
          <h3 class="text-xl font-semibold mb-4">个人信息</h3>
          <div class="mb-4">
            <label for="nickname" class="block mb-2">昵称</label>
            <input v-model="personalInfo.nickname" id="nickname" type="text" class="w-full p-2 border rounded">
          </div>
          <div class="mb-4">
            <label for="birthday" class="block mb-2">生日</label>
            <input v-model="personalInfo.birthday" id="birthday" type="date" class="w-full p-2 border rounded">
          </div>
          <div class="mb-4">
            <label for="motto" class="block mb-2">人生座右铭</label>
            <textarea v-model="personalInfo.motto" id="motto" rows="2" class="w-full p-2 border rounded"></textarea>
          </div>
          <div class="mb-4">
            <label for="longTermGoals" class="block mb-2">长期目标</label>
            <textarea v-model="personalInfo.longTermGoals" id="longTermGoals" rows="3" class="w-full p-2 border rounded"></textarea>
          </div>
          <div class="mb-4">
            <label for="values" class="block mb-2">价值观（用逗号分隔）</label>
            <input v-model="personalInfo.values" id="values" type="text" class="w-full p-2 border rounded">
          </div>
          <div class="mb-4">
            <label for="interests" class="block mb-2">兴趣爱好（用逗号分隔）</label>
            <input v-model="personalInfo.interests" id="interests" type="text" class="w-full p-2 border rounded">
          </div>
          <div class="mb-4">
            <label for="strengths" class="block mb-2">个人强项（用逗号分隔）</label>
            <input v-model="personalInfo.strengths" id="strengths" type="text" class="w-full p-2 border rounded">
          </div>
          <div class="mb-4">
            <label for="weaknesses" class="block mb-2">个人弱项（用逗号分隔）</label>
            <input v-model="personalInfo.weaknesses" id="weaknesses" type="text" class="w-full p-2 border rounded">
          </div>

          <!-- 在个人信息部分添加新字段 -->
          <div class="mb-4">
            <label for="lifeStage" class="block mb-2">当前人生阶段</label>
            <select v-model="personalInfo.lifeStage" id="lifeStage" class="w-full p-2 border rounded">
              <option value="student">学生</option>
              <option value="earlyCareer">初入职场</option>
              <option value="midCareer">职业中期</option>
              <option value="lateCareer">职业后期</option>
              <option value="retirement">退休</option>
            </select>
          </div>

          <div class="mb-4">
            <label for="nextStageExpectation" class="block mb-2">下一阶段期望</label>
            <textarea v-model="personalInfo.nextStageExpectation" id="nextStageExpectation" rows="3" class="w-full p-2 border rounded"></textarea>
          </div>

          <div class="mb-4">
            <label for="financialStatus" class="block mb-2">财务状况</label>
            <textarea v-model="personalInfo.financialStatus" id="financialStatus" rows="3" class="w-full p-2 border rounded"></textarea>
          </div>

          <div class="mb-4">
            <label for="financialGoals" class="block mb-2">财务目标</label>
            <textarea v-model="personalInfo.financialGoals" id="financialGoals" rows="3" class="w-full p-2 border rounded"></textarea>
          </div>

          <div class="mb-4">
            <label for="learningPlan" class="block mb-2">学习计划</label>
            <textarea v-model="personalInfo.learningPlan" id="learningPlan" rows="3" class="w-full p-2 border rounded"></textarea>
          </div>

          <div class="mb-4">
            <label for="careerStatus" class="block mb-2">当前职业状况</label>
            <textarea v-model="personalInfo.careerStatus" id="careerStatus" rows="3" class="w-full p-2 border rounded"></textarea>
          </div>

          <div class="mb-4">
            <label for="careerGoals" class="block mb-2">职业规划和目标</label>
            <textarea v-model="personalInfo.careerGoals" id="careerGoals" rows="3" class="w-full p-2 border rounded"></textarea>
          </div>

          <button @click="savePersonalInfo" class="btn btn-primary">保存个人信息</button>
        </div>

        <!-- 分类设置 -->
        <div v-if="activeSection === 'categories'">
          <h3 class="text-xl font-semibold mb-4">分类设置</h3>
          <div class="mb-4">
            <label for="newCategory" class="block mb-2">添加新分类</label>
            <div class="flex">
              <input v-model="newCategory" id="newCategory" type="text" class="flex-grow p-2 border rounded-l">
              <button @click="addCategory" class="btn btn-primary rounded-l-none">添加</button>
            </div>
          </div>
          <ul class="space-y-2">
            <li v-for="category in categories" :key="category" class="flex justify-between items-center">
              <span>{{ category }}</span>
              <button @click="removeCategory(category)" class="text-red-600 hover:text-red-800">删除</button>
            </li>
          </ul>
        </div>

        <!-- 通知设置 -->
        <div v-if="activeSection === 'notifications'">
          <h3 class="text-xl font-semibold mb-4">通知设置</h3>
          <div class="mb-4">
            <label for="reminderFrequency" class="block mb-2">复盘提醒频率</label>
            <select v-model="notificationSettings.reminderFrequency" id="reminderFrequency" class="w-full p-2 border rounded">
              <option value="daily">每天</option>
              <option value="weekly">每周</option>
              <option value="biweekly">每两周</option>
              <option value="monthly">每月</option>
            </select>
          </div>
          <div class="mb-4">
            <label class="block mb-2">提醒类型</label>
            <div>
              <label class="inline-flex items-center">
                <input type="checkbox" v-model="notificationSettings.eventReminders" class="form-checkbox">
                <span class="ml-2">事件提醒</span>
              </label>
            </div>
            <div>
              <label class="inline-flex items-center">
                <input type="checkbox" v-model="notificationSettings.goalReminders" class="form-checkbox">
                <span class="ml-2">目标提醒</span>
              </label>
            </div>
            <div>
              <label class="inline-flex items-center">
                <input type="checkbox" v-model="notificationSettings.reflectionReminders" class="form-checkbox">
                <span class="ml-2">复盘提醒</span>
              </label>
            </div>
          </div>
          <button @click="saveNotificationSettings" class="btn btn-primary">保存通知设置</button>
        </div>

        <!-- 导出导入 -->
        <div v-if="activeSection === 'dataManagement'">
          <h3 class="text-xl font-semibold mb-4">数据管理</h3>
          <div class="space-y-4">
            <button @click="exportData" class="btn btn-primary w-full">导出数据</button>
            <div>
              <label for="import-data" class="btn btn-secondary w-full cursor-pointer">
                导入数据
                <input id="import-data" type="file" @change="importData" class="hidden" accept=".json">
              </label>
            </div>
            <!-- 新增生成成长报告按钮 -->
            <button @click="generateGrowthReport" class="btn btn-primary w-full">生成成长报告</button>
          </div>
        </div>

        <!-- 云同步 -->
        <div v-if="activeSection === 'cloudSync'">
          <h3 class="text-xl font-semibold mb-4">云同步设置</h3>
          <div class="mb-4">
            <label for="cloudService" class="block mb-2">云存储服务</label>
            <select v-model="cloudSettings.service" id="cloudService" class="w-full p-2 border rounded">
              <option value="alioss">阿里云OSS</option>
              <option value="tencentcos">腾讯云COS</option>
              <option value="awss3">AWS S3</option>
            </select>
          </div>
          <div class="mb-4">
            <label for="accessKeyId" class="block mb-2">访问密钥ID</label>
            <input v-model="cloudSettings.accessKeyId" id="accessKeyId" type="text" class="w-full p-2 border rounded">
          </div>
          <div class="mb-4">
            <label for="accessKeySecret" class="block mb-2">访问密钥密码</label>
            <input v-model="cloudSettings.accessKeySecret" id="accessKeySecret" type="password" class="w-full p-2 border rounded">
          </div>
          <div class="mb-4">
            <label for="bucket" class="block mb-2">存储桶名称</label>
            <input v-model="cloudSettings.bucket" id="bucket" type="text" class="w-full p-2 border rounded">
          </div>
          <div class="mb-4">
            <label for="region" class="block mb-2">存储区域</label>
            <input v-model="cloudSettings.region" id="region" type="text" class="w-full p-2 border rounded">
          </div>
          <div class="mb-4">
            <label for="syncFrequency" class="block mb-2">自动同步频率</label>
            <select v-model="cloudSettings.syncFrequency" id="syncFrequency" class="w-full p-2 border rounded">
              <option value="daily">每天</option>
              <option value="weekly">每周</option>
              <option value="monthly">每月</option>
              <option value="manual">手动同步</option>
            </select>
          </div>
          <button @click="saveCloudSettings" class="btn btn-primary w-full mb-2">保存云同步设置</button>
          <button @click="syncToCloud" class="btn btn-secondary w-full mb-2">立即同步到云端</button>
          <button @click="syncFromCloud" class="btn btn-secondary w-full">从云端同步</button>
        </div>

        <!-- 帮助内容 -->
        <div v-if="activeSection === 'help'">
          <h3 class="text-xl font-semibold mb-4">📘 使用帮助</h3>
          <div class="space-y-6">
            <div>
              <h4 class="text-lg font-medium mb-2">🎯 目标模块</h4>
              <p>1. 点击顶部导航栏的"目标"进入目标页面。</p>
              <p>2. 点击"设置新目标"按钮创建新目标。</p>
              <p>3. 设置目标详情，包括标题、描述、截止日期、重要性等。</p>
              <p>4. 可以为目标设置子任务，帮助您更好地规划和追踪进度。</p>
              <p>5. 保存目标后，可以在目标列表中查看和更新进度。</p>
              <p>6. 定期更新目标进度，这将影响首页的目标完成度统计。</p>
            </div>
            <div>
              <h4 class="text-lg font-medium mb-2">📅 事件模块</h4>
              <p>1. 点击顶部导航栏的"事件"进入事件页面。</p>
              <p>2. 点击"添加新事件"按钮创建新事件。</p>
              <p>3. 填写事件详情，包括标题、日期、时间、地点、类别等。</p>
              <p>4. 可以为事件添加备注，记录重要信息。</p>
              <p>5. 保存事件后，它将显示在事件列表和首页的日历视图中。</p>
              <p>6. 事件可以与目标关联，帮助您追踪目标相关的具体行动。</p>
            </div>
            <div>
              <h4 class="text-lg font-medium mb-2">🔄 复盘模块</h4>
              <p>1. 点击顶部导航栏的"复盘"进入复盘页面。</p>
              <p>2. 点击"新建复盘"按钮开始新的复盘。</p>
              <p>3. 选择复盘模板（如标准、SWOT、5W2H等）。</p>
              <p>4. 填写复盘内容，包括成就、情绪、评分、学到的教训等。</p>
              <p>5. 可以关联相关的事件和目标，进行更全面的回顾。</p>
              <p>6. 保存复盘后，可以在复盘列表中查看历史记录。</p>
              <p>7. 最新的复盘将显示在首页的"最近复盘"区域。</p>
            </div>
            <div>
              <h4 class="text-lg font-medium mb-2">📊 统计模块</h4>
              <p>1. 点击顶部导航栏的"统计"进入统计页面。</p>
              <p>2. 查看各种数据可视化图表，了解您的生活和目标概况。</p>
              <p>3. 统计包括目标完成情况、事件分布、情绪变化等。</p>
              <p>4. 使用筛选器调整时间范围或类别以获取更具体的统计信息。</p>
              <p>5. 统计数据可以帮助您发现生活模式和改进空间。</p>
            </div>
            <div>
              <h4 class="text-lg font-medium mb-2">⚙️ 设置模块</h4>
              <p><strong>👤 个人信息：</strong></p>
              <p>- 设置您的昵称、生日、座右铭等个人信息。</p>
              <p>- 这些信息将用于个性化您的首页和其他模块。</p>
              
              <p><strong>🏷️ 分类设置：</strong></p>
              <p>- 添加、编辑或删除事件和目标的分类。</p>
              <p>- 自定义分类可以帮助您更好地组织和分析数据。</p>
              
              <p><strong>🔔 通知设置：</strong></p>
              <p>- 设置提醒频率和类型，如事件提醒、目标截止日期提醒等。</p>
              <p>- 根据您的偏好自定义通知，以获得最佳使用体验。</p>
              
              <p><strong>💾 导出导入：</strong></p>
              <p>- 导出您的所有数据为JSON文件，作为备份。</p>
              <p>- 导入之前导出的数据，恢复您的信息。</p>
              
              <p><strong>☁️ 云同步：</strong></p>
              <p>1. 选择云存储服务（如阿里云OSS、腾讯云COS、AWS S3）。</p>
              <p>2. 输入访问密钥ID和密码（从您选择的云服务提供商获取）。</p>
              <p>3. 填写存储桶名称和存储区域。</p>
              <p>4. 设置自动同步频率或选择手动同步。</p>
              <p>5. 点击"保存云同步设置"保存您的设置。</p>
              <p>6. 使用"立即同步到云端"或"从云端同步"按钮进行数据同步。</p>
              <p>注意：请妥善保管您的云服务密钥，不要泄露给他人。</p>
            </div>
            <div>
              <h4 class="text-lg font-medium mb-2">🔗 模块间的关联</h4>
              <p>- 目标可以关联多个事件，帮助追踪目标进展。</p>
              <p>- 复盘可以关联事件和目标，进行全面回顾。</p>
              <p>- 统计模块整合了所有其他模块的数据，提供全局视图。</p>
              <p>- 首页展示了来自所有模块的重要信息摘要。</p>
            </div>
            <div>
              <h4 class="text-lg font-medium mb-2">💡 使用技巧</h4>
              <p>- 定期更新目标进度和复盘，以获得最准确的统计和洞察。</p>
              <p>- 使用标签和分类功能，更好地组织您的事件和目标。</p>
              <p>- 充分利用不同的复盘模板，从多个角度审视您的生活和目标。</p>
              <p>- 经常查看统计数据，发现生活中的模式和趋势。</p>
              <p>- 定期备份您的数据，确保重要信息安全。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 报告模态框 -->
  <div v-if="showReportModalFlag" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="report-modal">
    <div class="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
      <div class="mt-3">
        <h3 class="text-lg font-medium text-gray-900 mb-4">成长报告</h3>
        <div class="mt-2 px-7 py-3">
          <p><strong>生成时间：</strong> {{ new Date(reportContent.generatedAt).toLocaleString() }}</p>
          <h4 class="font-medium mt-4">事件总结</h4>
          <p>总事件数：{{ reportContent.eventsSummary.totalEvents }}</p>
          <h4 class="font-medium mt-4">目标总结</h4>
          <p>总目标数：{{ reportContent.goalsSummary.totalGoals }}</p>
          <p>已完成目标：{{ reportContent.goalsSummary.completedGoals }}</p>
          <h4 class="font-medium mt-4">复盘总结</h4>
          <p>总复盘次数：{{ reportContent.reflectionsSummary.totalReflections }}</p>
          <p>平均评分：{{ reportContent.reflectionsSummary.averageScore.toFixed(2) }}</p>
          <h4 class="font-medium mt-4">整体进展</h4>
          <p>目标完成率：{{ reportContent.overallProgress.goalCompletionRate.toFixed(2) }}%</p>
          <p>复盘频率：每月 {{ reportContent.overallProgress.reflectionFrequency.toFixed(2) }} 次</p>
        </div>
        <div class="items-center px-4 py-3">
          <button @click="exportToTXT" class="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300 mb-2">
            导出为 TXT
          </button>
          <button @click="showReportModalFlag = false" class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300">
            关闭
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { exportData, importData } from '../utils/dataExport'
import { uploadData, downloadData } from '../utils/cloudStorage'
import { loadEvents, loadGoals, loadReflections, saveEvents, saveGoals, saveReflections } from '../utils/storage'
import { generateReport } from '../utils/reportGenerator'

// 移除 jsPDF 的导入
// import { jsPDF } from "jspdf";

// 在 setup 函数外部定义这个函数
function loadCustomFont() {
  // 我们将使用 jsPDF 内置的字体支持
  return 'undefined';
}

export default {
  setup() {
    const { proxy } = getCurrentInstance()
    const activeSection = ref('personal')
    const personalInfo = ref({
      nickname: '',
      birthday: '',
      motto: '',
      longTermGoals: '',
      values: '',
      interests: '',
      strengths: '',
      weaknesses: '',
      lifeStage: '',
      nextStageExpectation: '',
      financialStatus: '',
      financialGoals: '',
      learningPlan: '',
      careerStatus: '',
      careerGoals: ''
    })
    const categories = ref([])
    const newCategory = ref('')
    const notificationSettings = ref({
      reminderFrequency: 'weekly',
      eventReminders: true,
      goalReminders: true,
      reflectionReminders: true
    })
    const cloudSettings = ref({
      service: 'alioss',
      accessKeyId: '',
      accessKeySecret: '',
      bucket: '',
      region: '',
      syncFrequency: 'manual'
    })

    onMounted(() => {
      // 从本地存储加载个人信息、分类和通知设置
      const savedPersonalInfo = localStorage.getItem('personalInfo')
      if (savedPersonalInfo) {
        personalInfo.value = JSON.parse(savedPersonalInfo)
      }

      const savedCategories = localStorage.getItem('eventCategories')
      if (savedCategories) {
        categories.value = JSON.parse(savedCategories)
      } else {
        categories.value = ['工作', '学习', '家庭', '社交']
      }

      const savedNotificationSettings = localStorage.getItem('notificationSettings')
      if (savedNotificationSettings) {
        notificationSettings.value = JSON.parse(savedNotificationSettings)
      }

      const savedCloudSettings = localStorage.getItem('cloudSettings')
      if (savedCloudSettings) {
        cloudSettings.value = JSON.parse(savedCloudSettings)
      }
    })

    const savePersonalInfo = () => {
      localStorage.setItem('personalInfo', JSON.stringify(personalInfo.value))
      localStorage.setItem('userNickname', personalInfo.value.nickname) // 单独保存昵称
      alert('个人信息已保存')
    }

    const addCategory = () => {
      if (newCategory.value && !categories.value.includes(newCategory.value)) {
        categories.value.push(newCategory.value)
        localStorage.setItem('eventCategories', JSON.stringify(categories.value))
        newCategory.value = ''
        proxy.emitter.emit('categories-updated')
      }
    }

    const removeCategory = (category) => {
      categories.value = categories.value.filter(c => c !== category)
      localStorage.setItem('eventCategories', JSON.stringify(categories.value))
      proxy.emitter.emit('categories-updated')
    }

    const handleImportData = async (event) => {
      const file = event.target.files[0]
      if (file) {
        try {
          await importData(file)
          alert('数据导入成功')
          window.location.reload()
        } catch (error) {
          alert('数据导入失败：' + error.message)
        }
      }
    }

    const saveNotificationSettings = () => {
      localStorage.setItem('notificationSettings', JSON.stringify(notificationSettings.value))
      alert('通知设置已保存')
      proxy.emitter.emit('notification-settings-updated')
    }

    const saveCloudSettings = () => {
      // 移除可能的空格
      cloudSettings.value.accessKeyId = cloudSettings.value.accessKeyId.trim();
      cloudSettings.value.accessKeySecret = cloudSettings.value.accessKeySecret.trim();
      cloudSettings.value.bucket = cloudSettings.value.bucket.trim();
      cloudSettings.value.region = cloudSettings.value.region.trim();

      localStorage.setItem('cloudSettings', JSON.stringify(cloudSettings.value))
      alert('云同步设置已保存')
    }

    const syncToCloud = async () => {
      try {
        console.log('Preparing data for cloud sync...');
        const allData = {
          events: loadEvents(),
          goals: loadGoals(),
          reflections: loadReflections(),
          personalInfo: JSON.parse(localStorage.getItem('personalInfo') || '{}'),
        };
        console.log('Data prepared:', allData);

        console.log('Starting upload to cloud...');
        const userId = personalInfo.value.id || 'defaultUserId';
        console.log('Using user ID:', userId);
        await uploadData(userId, allData);
        console.log('Upload completed.');
        alert('数据已成功同步到云端');
      } catch (error) {
        console.error('同步到云端失败:', error);
        alert('同步失败：' + error.message);
      }
    };

    const syncFromCloud = async () => {
      try {
        console.log('Starting download from cloud...');
        const cloudData = await downloadData(personalInfo.value.id || 'defaultUserId'); // 使用用户ID
        console.log('Download completed:', cloudData);

        console.log('Updating local storage...');
        saveEvents(cloudData.events);
        saveGoals(cloudData.goals);
        saveReflections(cloudData.reflections);
        localStorage.setItem('personalInfo', JSON.stringify(cloudData.personalInfo));
        console.log('Local storage updated.');

        alert('已从云端同步数据');
        window.location.reload(); // 刷新页面以显示更新后的数据
      } catch (error) {
        console.error('从云端同步失败:', error);
        alert('同步失败：' + error.message);
      }
    };

    const generateGrowthReport = async () => {
      try {
        const report = await generateReport()
        showReportModal(report)
      } catch (error) {
        console.error('生成报告失败:', error)
        alert('生成报告失败: ' + error.message)
      }
    }

    const showReportModal = (report) => {
      // 这里我们将使用一个新的响应式变量来控制模态框的显示
      reportContent.value = report
      showReportModalFlag.value = true
    }

    // 在 setup 函数中添加这些响应式变量
    const reportContent = ref(null)
    const showReportModalFlag = ref(false)

    // 将 exportToPDF 函数改为 exportToTXT
    const exportToTXT = () => {
      const content = [
        '成长报告',
        `生成时间：${new Date(reportContent.value.generatedAt).toLocaleString()}`,
        '',
        '事件总结',
        `总事件数：${reportContent.value.eventsSummary.totalEvents}`,
        '',
        '目标总结',
        `总目标数：${reportContent.value.goalsSummary.totalGoals}`,
        `已完成目标：${reportContent.value.goalsSummary.completedGoals}`,
        '',
        '复盘总结',
        `总复盘次数：${reportContent.value.reflectionsSummary.totalReflections}`,
        `平均评分：${reportContent.value.reflectionsSummary.averageScore.toFixed(2)}`,
        '',
        '整体进展',
        `目标完成率：${reportContent.value.overallProgress.goalCompletionRate.toFixed(2)}%`,
        `复盘频率：每月 ${reportContent.value.overallProgress.reflectionFrequency.toFixed(2)} 次`
      ].join('\n');

      const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = '成长报告.txt';
      link.click();
      URL.revokeObjectURL(url);
    }

    return {
      activeSection,
      personalInfo,
      categories,
      newCategory,
      notificationSettings,
      cloudSettings,
      savePersonalInfo,
      addCategory,
      removeCategory,
      exportData,
      importData: handleImportData,
      saveNotificationSettings,
      saveCloudSettings,
      syncToCloud,
      syncFromCloud,
      generateGrowthReport,
      showReportModal,
      reportContent,
      showReportModalFlag,
      exportToTXT,
    }
  }
}
</script>