<template>
  <div class="bg-white rounded-lg shadow-md p-6">
    <h2 class="text-2xl font-bold mb-6 text-gray-800">复盘总结</h2>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <h3 class="text-xl font-semibold mb-4">情绪趋势</h3>
        <canvas ref="moodChart"></canvas>
      </div>
      <div>
        <h3 class="text-xl font-semibold mb-4">评分趋势</h3>
        <canvas ref="scoreChart"></canvas>
      </div>
      <div>
        <h3 class="text-xl font-semibold mb-4">标签云</h3>
        <div ref="tagCloud" class="h-64"></div>
      </div>
      <div>
        <h3 class="text-xl font-semibold mb-4">复盘统计</h3>
        <p>总复盘次数: {{ reflections.length }}</p>
        <p>平均评分: {{ averageScore.toFixed(2) }}</p>
        <p>最常用模板: {{ mostUsedTemplate }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import Chart from 'chart.js/auto'
import * as d3 from 'd3'
import cloud from 'd3-cloud'
import { loadReflections } from '../utils/storage'

export default {
  setup() {
    const moodChart = ref(null)
    const scoreChart = ref(null)
    const tagCloud = ref(null)
    const reflections = ref([])

    const averageScore = computed(() => {
      const scores = reflections.value.map(r => r.score)
      return scores.reduce((a, b) => a + b, 0) / scores.length
    })

    const mostUsedTemplate = computed(() => {
      const templates = reflections.value.map(r => r.template)
      return templates.sort((a,b) =>
        templates.filter(v => v === a).length
        - templates.filter(v => v === b).length
      ).pop()
    })

    onMounted(() => {
      reflections.value = loadReflections()
      createMoodChart()
      createScoreChart()
      createTagCloud()
    })

    const createMoodChart = () => {
      const moodData = reflections.value.map(r => ({ date: r.date, mood: r.mood }))
      new Chart(moodChart.value, {
        type: 'line',
        data: {
          labels: moodData.map(d => d.date),
          datasets: [{
            label: '情绪趋势',
            data: moodData.map(d => {
              switch(d.mood) {
                case 'very_happy': return 5
                case 'happy': return 4
                case 'neutral': return 3
                case 'sad': return 2
                case 'very_sad': return 1
                default: return 3
              }
            }),
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              max: 5,
              ticks: {
                stepSize: 1,
                callback: function(value) {
                  return ['很差', '差', '一般', '好', '很好'][value - 1]
                }
              }
            }
          }
        }
      })
    }

    const createScoreChart = () => {
      const scoreData = reflections.value.map(r => ({ date: r.date, score: r.score }))
      new Chart(scoreChart.value, {
        type: 'line',
        data: {
          labels: scoreData.map(d => d.date),
          datasets: [{
            label: '评分趋势',
            data: scoreData.map(d => d.score),
            borderColor: 'rgb(153, 102, 255)',
            tension: 0.1
          }]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              max: 10
            }
          }
        }
      })
    }

    const createTagCloud = () => {
      const tags = reflections.value.flatMap(r => r.tags)
      const tagCounts = tags.reduce((acc, tag) => {
        acc[tag] = (acc[tag] || 0) + 1
        return acc
      }, {})

      const words = Object.keys(tagCounts).map(tag => ({
        text: tag,
        size: 10 + tagCounts[tag] * 5
      }))

      const layout = cloud()
        .size([300, 300])
        .words(words)
        .padding(5)
        .rotate(() => 0)
        .font("Impact")
        .fontSize(d => d.size)
        .on("end", draw)

      layout.start()

      function draw(words) {
        d3.select(tagCloud.value).append("svg")
          .attr("width", layout.size()[0])
          .attr("height", layout.size()[1])
          .append("g")
          .attr("transform", "translate(" + layout.size()[0] / 2 + "," + layout.size()[1] / 2 + ")")
          .selectAll("text")
          .data(words)
          .enter().append("text")
          .style("font-size", d => d.size + "px")
          .style("font-family", "Impact")
          .attr("text-anchor", "middle")
          .attr("transform", d => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")")
          .text(d => d.text)
      }
    }

    return {
      reflections,
      moodChart,
      scoreChart,
      tagCloud,
      averageScore,
      mostUsedTemplate
    }
  }
}
</script>
