<template>
  <div class="bg-white rounded-lg shadow-md p-6">
    <h2 class="text-2xl font-bold mb-6 text-gray-800">{{ editingGoal ? '编辑目标' : '目标设定' }}</h2>
    
    <form @submit.prevent="submitGoal">
      <div class="mb-4">
        <label for="title" class="block mb-2 text-sm font-medium text-gray-700">标题</label>
        <input v-model="goal.title" id="title" type="text" required class="w-full p-2 border rounded">
      </div>
      
      <div class="mb-4">
        <label for="description" class="block mb-2 text-sm font-medium text-gray-700">描述</label>
        <textarea v-model="goal.description" id="description" rows="3" class="w-full p-2 border rounded"></textarea>
      </div>
      
      <div class="mb-4">
        <label for="startDate" class="block mb-2 text-sm font-medium text-gray-700">开始日期</label>
        <input v-model="goal.startDate" id="startDate" type="date" required class="w-full p-2 border rounded">
      </div>
      
      <div class="mb-4">
        <label for="endDate" class="block mb-2 text-sm font-medium text-gray-700">结束日期</label>
        <input v-model="goal.endDate" id="endDate" type="date" required class="w-full p-2 border rounded">
      </div>
      
      <div class="mb-4">
        <label for="progress" class="block mb-2 text-sm font-medium text-gray-700">进度</label>
        <input v-model.number="goal.progress" id="progress" type="number" min="0" max="100" class="w-full p-2 border rounded">
      </div>
      
      <div class="mb-4">
        <label for="relatedStrengths" class="block mb-2 text-sm font-medium text-gray-700">相关强项</label>
        <select v-model="goal.relatedStrengths" id="relatedStrengths" multiple class="w-full p-2 border rounded">
          <option v-for="strength in personalStrengths" :key="strength" :value="strength">{{ strength }}</option>
        </select>
      </div>
      
      <div v-if="strengthSuggestion" class="mb-4 p-4 bg-blue-100 rounded">
        <p class="text-blue-800">{{ strengthSuggestion }}</p>
      </div>
      
      <!-- 子任务部分 -->
      <div class="mb-4">
        <h3 class="text-lg font-semibold mb-2">子任务</h3>
        <ul class="space-y-2">
          <li v-for="(subTask, index) in goal.subTasks" :key="index" class="flex items-center">
            <input v-model="subTask.title" type="text" class="flex-grow p-2 border rounded mr-2">
            <button @click="removeSubTask(index)" type="button" class="text-red-600 hover:text-red-800">
              <i class="fas fa-trash"></i>
            </button>
          </li>
        </ul>
        <button @click="addSubTask" type="button" class="mt-2 text-blue-600 hover:text-blue-800">
          <i class="fas fa-plus"></i> 添加子任务
        </button>
      </div>
      
      <button type="submit" class="btn btn-primary">{{ editingGoal ? '更新目标' : '创建目标' }}</button>
    </form>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { loadPersonalInfo } from '../utils/storage'

export default {
  props: {
    editingGoal: {
      type: Object,
      default: null
    }
  },
  emits: ['goal-added', 'goal-updated'],
  setup(props, { emit }) {
    const goal = ref({
      id: '',
      title: '',
      description: '',
      startDate: '',
      endDate: '',
      progress: 0,
      subTasks: [],
      relatedStrengths: []
    })

    const personalInfo = ref({})
    const personalStrengths = computed(() => personalInfo.value.strengths?.split(',').map(s => s.trim()) || [])

    const strengthSuggestion = computed(() => {
      if (goal.value.relatedStrengths.length > 0) {
        return `利用您的 ${goal.value.relatedStrengths.join('、')} 优势，您可以更好地实现这个目标。考虑如何充分发挥这些强项。`
      }
      return ''
    })

    onMounted(() => {
      personalInfo.value = loadPersonalInfo()
    })

    const resetForm = () => {
      goal.value = {
        id: '',
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        progress: 0,
        subTasks: [],
        relatedStrengths: []
      }
    }

    const submitGoal = () => {
      const newGoal = { ...goal.value }
      if (!newGoal.id) {
        newGoal.id = uuidv4()
        emit('goal-added', newGoal)
      } else {
        emit('goal-updated', newGoal)
      }
      resetForm()
    }

    const addSubTask = () => {
      goal.value.subTasks.push({ id: uuidv4(), title: '', completed: false })
    }

    const removeSubTask = (index) => {
      goal.value.subTasks.splice(index, 1)
    }

    watch(() => props.editingGoal, (newVal) => {
      if (newVal) {
        goal.value = { ...newVal }
      } else {
        resetForm()
      }
    }, { immediate: true })

    return {
      goal,
      submitGoal,
      addSubTask,
      removeSubTask,
      personalStrengths,
      strengthSuggestion
    }
  }
}
</script>
