<template>
  <div class="container mx-auto px-4 py-8">
    <h2 class="text-3xl font-bold mb-8 text-gray-800">复盘</h2>
    <div class="mb-4 space-x-4">
      <button @click="toggleForm" class="btn btn-primary">
        {{ showForm ? '查看复盘记录' : '添加新复盘' }}
      </button>
      <button @click="showSummary = !showSummary" class="btn btn-secondary">
        {{ showSummary ? '隐藏总结' : '查看总结' }}
      </button>
    </div>
    <Reflection 
      v-if="showForm" 
      @reflection-added="onReflectionAdded" 
      @reflection-updated="onReflectionUpdated"
      :editingReflection="editingReflection" 
    />
    <ReflectionList 
      v-else-if="!showSummary" 
      @edit-reflection="onEditReflection" 
      :key="listKey" 
    />
    <ReflectionSummary v-else />
  </div>
</template>

<script>
import { ref } from 'vue'
import Reflection from '../components/Reflection.vue'
import ReflectionList from '../components/ReflectionList.vue'
import ReflectionSummary from '../components/ReflectionSummary.vue'

export default {
  components: {
    Reflection,
    ReflectionList,
    ReflectionSummary
  },
  setup() {
    const showForm = ref(false)
    const showSummary = ref(false)
    const editingReflection = ref(null)
    const listKey = ref(0)

    const toggleForm = () => {
      showForm.value = !showForm.value
      if (!showForm.value) {
        editingReflection.value = null
        listKey.value += 1 // 强制重新渲染 ReflectionList
      }
    }

    const onReflectionAdded = () => {
      showForm.value = false
      editingReflection.value = null
      listKey.value += 1 // 强制重新渲染 ReflectionList
    }

    const onReflectionUpdated = () => {
      showForm.value = false
      editingReflection.value = null
      listKey.value += 1 // 强制重新渲染 ReflectionList
    }

    const onEditReflection = (reflection) => {
      editingReflection.value = reflection
      showForm.value = true
    }

    return {
      showForm,
      showSummary,
      editingReflection,
      listKey,
      toggleForm,
      onReflectionAdded,
      onReflectionUpdated,
      onEditReflection
    }
  }
}
</script>
